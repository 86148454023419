export function onFormFieldChange(context, field, newValue){
  return {type: FORM_FIELD_CHANGED, context: context, field: field, value: newValue}
}

export function onPlanCustomerFieldChange( field, newValue){
  return {type: FORM_PLAN_CUSTOMER_CHANGED, field: field, value: newValue}
}

export function onFormError(context, errorMessage){
  return {type: FORM_ERROR, context: context, errorMessage: errorMessage}
}

export function onFormSuccess(context){
  return {type: FORM_SUCCESS, context: context, errorMessage: undefined}
}

export function clearFormValues(context) {
  return {type: CLEAR_FORM_VALUES, context: context}
}

export function setEditingMode(context, value){
  return {type: FORM_SET_EDITING, context: context, value: value}
}

export function setReadOnlyMode(context, value){
  return {type: FORM_SET_READONLY, context: context, value: value}
}

export function insertEmitBilletForm(values) {
  return {
    type: FORM_EMIT_BILLET_INSERTED,
    value: values
  }
}

export function setFormValues(context, values){
  return dispatch => {
    dispatch( onFormSuccess(context) )
    dispatch({type: FORM_SET_VALUES, context: context, values: values})
  }
}

export const FORM_FIELD_CHANGED = 'FORM_FIELD_CHANGED'
export const FORM_ERROR = 'FORM_ERROR'
export const FORM_SUCCESS = 'FORM_SUCCESS'
export const FORM_SET_VALUES = 'FORM_SET_VALUES'
export const CLEAR_FORM_VALUES = 'CLEAR_FORM_VALUES'
export const FORM_SET_EDITING = 'FORM_SET_EDITING'
export const FORM_SET_READONLY = 'FORM_SET_READONLY'
export const FORM_EMIT_BILLET_INSERTED = "FORM_EMIT_BILLET_INSERTED"
export const CLEAR_FORM_EMIT_BILLET = "CLEAR_FORM_EMIT_BILLET"
export const FORM_PLAN_CUSTOMER_CHANGED = "FORM_PLAN_CUSTOMER_CHANGED";