import React, {Component} from 'react';
import AddressAutoComplete from '../containers/AddressAutoComplete';
import './attendenceAddressTab.css';
import AttendenceMap from './AttendanceMap';
import {connect} from 'react-redux';
import request from 'superagent';
import Select from 'react-select';
import AddressModal from '../attendance/tabs/AddressModal';
import {updateFavoriteDestination} from '../../api/address_helper';
import toastr from 'toastr';
import {updateConcessionaire} from '../../api/address_helper';
import {
  onChangeEstimationDistance,
  onChangeEstimationTime,
  setAttendanceOriginAddress,
  fetchFavoriteDestinationList,
} from '../../actions/attendence_actions';
import Swal from 'sweetalert2';
import {
  loadFavoriteDestiantionList,
  onAddressChange,
  changeFavoriteDestination,
} from '../../actions/address_actions';
import {getDistance} from '../../utils/map_helper.js';
import {CODIGO_DAF, USUARIO_TIPO_REBOQUEME} from '../../reducers/constants/index';
import PropTypes from 'prop-types';

const originAddressStyles = {
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 999999,
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      cursor: 'pointer',
    };
  },
};

const codigoDAF = '2322661870558778503';
const codigoKIA = '2735238496074601893';

class AttendenceAddressTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpened: false,
      favoriteDestination: null,
      favoriteDestinationCode: null,
    };
    this.saveConcessionaria = this.saveConcessionaria.bind(this);
  }

  componentDidMount() {
    const user_info = JSON.parse(localStorage.getItem('user_info'));

    let companyCode = '';

    if (this.props.company) {
      companyCode = this.props.company.value;
    }

    if (user_info.empresa) {
      companyCode = user_info.empresa.codigo;
    }
    this.props.fetchFavoriteList(companyCode);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company) {
      this.props.fetchFavoriteList(this.props.company.value);
    }
  }

  getFavoriteDestinarions = () => {
    const addressesSorted = this.props.favoriteDestinationsList
      .map((destination) => {
        const distance = getDistance(this.props.requestDestiny, destination.endereco);
        return {
          className: 'favorite-address',
          codigo: destination.codigo,
          label: '✭  ' + destination.descricao,
          value: {
            ...destination,
          },
          location: {
            lat: destination.endereco.latitude,
            lng: destination.endereco.longitude,
            distance,
          },
          ...destination.endereco,
          type: 'favorite',
        };
      })
      .sort((a, b) => a.location.distance - b.location.distance);

    return addressesSorted;
  };

  saveConcessionaria = (codigo) => {
    updateFavoriteDestination(codigo, {
      enderecoFavorito: this.state.favoriteDestinationCode,
    }).then((e) =>
      e.codigoRetorno === 201
        ? toastr.success(e.mensagemCliente)
        : toastr.error(e.mensagemCliente),
    );
  };

  changeOrigin = (address) => {
    let favoriteDestination;
    let favoriteDestinationCode;
    try {
      favoriteDestinationCode = address.value.codigo;
      favoriteDestination = address.value.contatosEnderecoFavorito;
    } catch (error) {
      favoriteDestination = null;
      favoriteDestinationCode = null;
    }
    this.setState({favoriteDestination, favoriteDestinationCode});
    this.props.addressSelected('enderecoOrigem', address);
  };

  convertMeterToKm = (meter) => {
    return meter ? meter / 1000 : 0;
  };

  getTooltipInfo = ({value = {}} = {}) => {
    const {endereco, email, ...others} = value;
    if (endereco) {
      let tooltip = `${endereco.logradouro}\n${endereco.bairro}`;

      if (email) {
        tooltip += `\n${email}\n`;
      } else {
        tooltip += '\n';
      }

      if (others.contato && others.telefoneContato) {
        tooltip += `${others.contato} ${others.telefoneContato} `;
      }

      if (others.contato2 && others.telefoneContato2) {
        tooltip += `| ${others.contato2} ${others.telefoneContato2} `;
      }

      if (others.contato3 && others.telefoneContato3) {
        tooltip += `| ${others.contato3} ${others.telefoneContato3}`;
      }

      if (endereco.codigo) {
        tooltip += `${endereco.codigo}`;
      }
      return tooltip;
    }

    return null;
  };

  alterarConcessionaria = () => {
    const {attendanceId, updateAtendimentoTab} = this.props;
    const selectedConcessionaria = this.state.favoriteDestination;
    const codigoEmpresa = this.state.favoriteDestinationCode;

    if (!selectedConcessionaria) {
      return toastr.error('Nenhuma concessionária selecionada.');
    }

    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você deseja alterar a concessionária?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5c40ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, alterar!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        changeFavoriteDestination(attendanceId, codigoEmpresa)
          .then((results) => {
            toastr.success('Alteração realizada com sucesso!');
            updateAtendimentoTab();
          })
          .catch((error) => {
            toastr.error('Não foi possivel fazer a alteração');
          });
      }
    });
  };

  render() {
    const {
      requestOrigin,
      requestDestiny,
      attendanceTab,
      company: {value: empresa} = {},
      attendence,
      attendanceId,
      companyName: {label: companyName} = {},
    } = this.props;
    const codigo = attendence.attendanceTab.codigo;

    const favoriteDestinations = this.getFavoriteDestinarions();
    const tooltipConcessionaria = this.getTooltipInfo(this.props.originAddress);
    const favortieDestinationState = this.state.favoriteDestination;

    const user_info = JSON.parse(localStorage.getItem('user_info'));
    const isSoon = user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME;
    const isDaf = user_info.empresa.codigo === CODIGO_DAF;
    console.log(isDaf);

    function getLocais() {
      try {
        const locais =
          favortieDestinationState ||
          attendence.attendanceTab.enderecoFavorito.contatosEnderecoFavorito ||
          [];
        return locais;
      } catch (error) {
        return [];
      }
    }

    const locais = getLocais();

    return (
      <section>
        {this.state.isModalOpened && (
          <AddressModal close={() => this.setState({isModalOpened: false})} tabs={locais} />
        )}
        <div id="attendence-origin" className="col-sm-6">
          <div className="row">
            <div className="col-sm-12">
              <label className="font-normal p-w-xs">Local do Cliente</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <i className="fas fa-flag" />
                </span>
                <AddressAutoComplete
                  placeholder=""
                  id="enderecoDestino"
                  isDaf={isDaf}
                  initialValue={requestDestiny ? requestDestiny.logradouro : null}
                />
              </div>
            </div>
          </div>
          {(isDaf || isSoon) && (
            <section>
              <div className="row" style={{marginTop: '15px'}}>
                <div className="col-sm-12">
                  <label className="font-normal p-w-xs">
                    Concessionária{'  '}
                    {!!locais.length && favortieDestinationState !== undefined && (
                      <span
                        style={{cursor: 'pointer'}}
                        onClick={() => this.setState({isModalOpened: true})}>
                        <i className="fa fa-info-circle" />
                      </span>
                    )}
                  </label>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="fas fa-location-arrow" />
                    </span>
                    <Select
                      id="enderecoOrigem"
                      options={favoriteDestinations}
                      onChange={this.changeOrigin}
                      value={this.props.originAddress}
                      styles={originAddressStyles}
                    />
                    {this.state.favoriteDestination && (
                      <span
                        style={{cursor: 'pointer'}}
                        onClick={() => this.saveConcessionaria(codigo)}
                        className="input-group-addon btn btn-primary">
                        <i className="fas fa-save" />
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row" style={{marginTop: '15px'}}>
                <div className="col-sm-6">
                  <label className="font-normal p-w-xs">Km</label>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="fas fa-car" />
                    </span>
                    <input
                      onChange={(e) => this.props.onChangeDistance(e.target.value)}
                      value={this.convertMeterToKm(this.props.distance)}
                      type="number"
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <label className="font-normal p-w-xs">Tempo</label>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="fas fa-clock" />
                    </span>
                    <input
                      type="text"
                      onChange={(e) => this.props.onChangeTime(e.target.value)}
                      value={this.props.time}
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
              </div>

              {(attendanceTab.kmAtualValidado || attendanceTab.kmTotalRodadoMecanico) &&
                empresa === codigoDAF && (
                  <div className="row" style={{marginTop: '15px'}}>
                    <div className="col-sm-6">
                      <label className="font-normal p-w-xs">Km Atual Validado</label>
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="fas fa-car" />
                        </span>
                        <input
                          value={attendanceTab.kmAtualValidado}
                          type="number"
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label className="font-normal p-w-xs">Km Total Mecânico</label>
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="fas fa-clock" />
                        </span>
                        <input
                          type="text"
                          onChange={(e) => this.props.onChangeTime(e.target.value)}
                          value={attendanceTab.kmTotalRodadoMecanico}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                )}
            </section>
          )}
          {attendanceId && (isDaf || isSoon) && (
            <button
              style={{marginTop: '140px'}}
              className="btn btn-primary"
              onClick={this.alterarConcessionaria}>
              Alterar Concessionária
            </button>
          )}
        </div>
        <div className="col-sm-6">
          <AttendenceMap
            id="attendence-map"
            requestOrigin={{
              ...requestOrigin,
              lat: requestOrigin.latitude,
              lng: requestOrigin.longitude,
            }}
            tipoServico="REBOQUE"
            cancelEstimation={!!this.props.time}
            requestDestination={{
              ...requestDestiny,
              lat: requestDestiny.latitude,
              lng: requestDestiny.longitude,
            }}
            style={{
              height: '390px',
            }}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  requestOrigin: state.service.enderecoOrigem,
  requestDestiny: state.service.enderecoDestino,
  attendanceTab: state.attendance.attendanceTab,
  endereco: state.attendance.attendanceTab.endereco,
  enderecoFavorito: state.attendance.attendanceTab.enderecoFavorito,
  distance: state.attendance.estimation.distance,
  time: state.attendance.estimation.time,
  originAddress: state.attendance.originAddress,
  favoriteDestinationsList: state.attendance.favoriteDestinations,
  empresa: state.login.user_info.empresa.codigo,
  attendence: state.attendance,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeTime: (value) => dispatch(onChangeEstimationTime(value)),
    onChangeDistance: (value) => dispatch(onChangeEstimationDistance(value)),
    loadFavoriteList: () => dispatch(loadFavoriteDestiantionList()),
    fetchFavoriteList: (codigoEmpresa) =>
      dispatch(fetchFavoriteDestinationList(codigoEmpresa)),
    addressSelected: (id, address) => {
      dispatch([
        onAddressChange(id, address),
        setAttendanceOriginAddress({
          ...address,
          label: address.value.descricao,
        }),
      ]);
    },
  };
};

AttendenceAddressTab.propTypes = {
  company: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendenceAddressTab);
