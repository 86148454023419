import React, {Component} from 'react';
import propsTypes from 'prop-types';
import {CSVLink} from 'react-csv';
import {
  hasKavakProfile,
  hasKoviProfile,
  hasReboqueMeProfile,
} from '../../utils/check_permission';

class CsvExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvData: false,
      loading: false,
      fetchingCVSProgress: 0,
    };

    this.retriveData = () => {
      const {asyncGetData} = this.props;
      asyncGetData();
    };
  }

  prepareDataForExport = (data) => {
    return data.map((data) => {
      const newData = {...data};

      if (data.dataCadastro) {
        const [dia, hora] = data.dataCadastro.split(' ');
        newData.dataCadastro = dia;
        newData.horaCadastro = hora;
      }

      if (
        hasKoviProfile() &&
        data.respostaAceita &&
        data.respostaAceita.distanciaSolicitante
      ) {
        newData.clienteDistancia = data.respostaAceita.distanciaSolicitante;
      }
      if (hasKoviProfile() && data.respostaAceita && data.respostaAceita.distanciaTotal) {
        newData.distanciaTotal = data.respostaAceita.distanciaTotal;
      }
      if (!data.enderecoDestino) {
        newData.enderecoDestino = {};
        newData.enderecoDestino.logradouro = '';
      }

      if (!data.enderecoOrigem) {
        newData.enderecoOrigem = {};
        newData.enderecoOrigem.logradouro = '';
      }

      if (!data.cliente) {
        newData.cliente = {};
        newData.cliente.nome = '';
      }

      if (!data.user) {
        newData.user = {};
        newData.user.nome = '';
      }
      if (data.dataConcluida) {
        const [dia, hora] = data.dataConcluida.split(' ');
        newData.dataConcluida = dia;
        newData.horaConcluida = hora;
      }

      if (data.dataChegadadoParceiro) {
        const [dia, hora] = data.dataChegadadoParceiro.split(' ');
        newData.dataChegadadoParceiro = dia;
        newData.horaChegadadoParceiro = hora;
      }
      if (data.atendimento) {
        if (data.atendimento.tipoMotivo) {
          newData.tipoRequisicao = data.atendimento.tipoMotivo.descricao;
        }
        if (data.atendimento.user) {
          newData.usuarioAtendimento = data.atendimento.user.nome;
          if (
            data.atendimento &&
            data.atendimento.user &&
            Array.isArray(data.atendimento.user.departamento)
          ) {
            const mappedDescriptions = data.atendimento.user.departamento.map(
              (depto) => depto.description,
            );
            newData.usuarioAtendimentoDepartamento = mappedDescriptions.join(', ');
          }
        }
      }
      if (
        data.manualConclusion &&
        data.manualConclusion.code === 'MANUALCLIENTENAOENCONTRADO'
      ) {
        newData.clienteEncontrado = 'Não';
      } else {
        newData.clienteEncontrado = 'Sim';
      }

      if (data.isItScheduled) {
        newData.agendado = 'Sim';
      } else {
        newData.agendado = 'Não';
      }

      if (data.observacao) {
        newData.observacao = data.observacao.replace(/\"|\'/gm, '').replace(/\n/gm, ' ');
      }

      if (
        data.respostaAceita &&
        data.respostaAceita.dataAceite &&
        (hasKavakProfile() || hasKoviProfile() || hasReboqueMeProfile())
      ) {
        newData.dataAceite = data.respostaAceita.dataAceite;
      }

      if (data.atendimento && data.atendimento.requisicao) {
        newData.descricao = data.atendimento.requisicao.replace(/"/g, "'");
      }

      return newData;
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.setState({csvData: false});
    }
  }
  componentWillReceiveProps(nextProps) {
    const {totalItems, isFetchingCVS, data, fetchingCVSProgress} = nextProps;
    this.setState({
      loading: isFetchingCVS,
      fetchingCVSProgress: fetchingCVSProgress,
    });

    if (data.length !== 0 && data.length === totalItems) {
      const csvData = this.prepareDataForExport(data);
      this.setState({
        csvData: csvData,
        loading: false,
      });
    }
  }

  render() {
    const {
      children,
      filename,
      headers,
      className,
      totalItems,
      fetchingCVSProgress,
    } = this.props;

    const {csvData, loading} = this.state;

    const progress = (
      (fetchingCVSProgress / ((totalItems / 2000).toFixed(1) + 1)) *
      100
    ).toFixed(0);

    return (
      <div>
        {csvData && (
          <CSVLink
            separator={';'}
            data={csvData}
            headers={headers}
            filename={filename}
            className={className}>
            {children}
          </CSVLink>
        )}
        {loading && !csvData && (
          <div className="btn btn-primary">
            <i className="fas fa-spinner fa-spin" />
            <span style={{marginLeft: '10px', marginRight: '2px'}}>{progress}%</span>
          </div>
        )}
        {!loading && !csvData && (
          <div onClick={this.retriveData} className="btn btn-primary">
            <i className="fas fa-file-export" />
            <span style={{marginLeft: '5px', marginRight: '2px'}}>Gerar .CSV</span>
          </div>
        )}
      </div>
    );
  }
}

CsvExport.defaultProps = {
  children: undefined,
  asyncGetData: () => null,
};

CsvExport.propTypes = {
  children: propsTypes.node,
  asyncGetData: propsTypes.func,
};

export default CsvExport;
