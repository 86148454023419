import swal from 'sweetalert';
import {disableButtons, enableButtons, close} from 'sweetalert';
import {push} from 'react-router-redux';
import moment from 'moment';

import {toastError} from './toast_actions';
import {startTimer} from './timer_actions';
import {confirmDialog} from './confirm_actions';
import {createVehicle} from '../api/vehicle_helper';
import {
  createSolicitation,
  getPricePrediction,
  getAllPricePrediction,
  verifyActivation,
  callPartner,
} from '../api/solicitation_helper';
import {doPost, doGet} from '../api/base/base_api_helper';

export function showMotivoPatio() {
  return {
    type: SHOW_MOTIVO_PATIO,
  };
}
export function updateMotivoPatio(motivoPatio) {
  return {
    type: UPDATE_MOTIVO_PATIO,
    payload: motivoPatio,
  };
}

export function sendSolicitation(price, auth, posPatio, saveAddress) {
  return (dispatch, getState) => {
    let serviceState = {
      ...getState().service,
      motivoPatio: getState().service.motivoPatio,
    };

    if (posPatio && saveAddress) {
      serviceState.enderecoDestinoAposPatio = posPatio;
      serviceState.enderecoDestino = saveAddress;
    }
    if (
      serviceState.tipoServico == 'REBOQUE' &&
      (!serviceState.enderecoDestino || !serviceState.enderecoDestino.latitude)
    ) {
      dispatch({
        type: SERVICE_ERROR,
        message: 'É necessário informar o endereço de destino',
      });
      return;
    }

    dispatch({type: SERVICE_FETCHING, payload: true});

    createSolicitation({...serviceState, price}, auth)
      .then((result) => {
        dispatch({type: SERVICE_CREATED, service: result});
        dispatch(push('/followup/' + result.solicitacao.codigo));
        enableButtons();
      })
      .catch((error) => {
        if (error.codigoRetorno === 400 && error.campoErro === 'motivoPatio') {
          dispatch(showMotivoPatio());
        }
        dispatch({type: SERVICE_ERROR, message: error.mensagemCliente});
        enableButtons();
      });
  };
}
export function sendSolicitationModal(solicitationData, auth) {
  return createSolicitation(solicitationData, auth)
    .then((result) => {
      enableButtons();
      return result;
    })
    .catch((error) => {
      throw error;
    });
}

export function callPartners(partnerId, solicitationCode, priority = false) {
  if (solicitationCode) {
    return callPartner(solicitationCode, partnerId, undefined, priority)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });
  }
}
export function getPriceAndTimePrediction(request_info, auth) {
  return (dispatch, getState) => {
    confirmDialog(
      {
        title: `<h2>Preço: R$${request_info.price} <br/> Tempo: ${
          request_info.time
        } - ${parseInt(request_info.time * 1.3)} minutos</h2>`,
        text: `<span style="color:#F8BB86">Ao confirmar a solicitação do serviço não será possível mais cancelar.<span style="color:#F8BB86">`,
        type: 'success',
        html: true,
        confirmButtonColor: '#25f4af',
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: true,
      },
      () => {
        disableButtons();
        dispatch(sendSolicitation(request_info.price, auth));
      },
    );
  };
}

export function getAllPricePredictionExecution(serviceInfo) {
  return (dispatch, getState) => {
    const service = serviceInfo ? serviceInfo : getState().service;
    getAllPricePrediction(service, getState().account.auth).then((promisses) => {
      Promise.all(promisses).then((result) => {
        dispatch([
          {
            type: 'ALL_PRICES_FETCHED',
            carPrice: preparedPrice(result[1].valor, getState().service.sliderValue),
            suvPrice: preparedPrice(result[2].valor, getState().service.sliderValue),
            vanPrice: preparedPrice(result[3].valor, getState().service.sliderValue),
            avgTime: result[1].tempoEspera,
          },
          {
            type: 'ALL_ORIGINAL_PRICES_FETCHED',
            originalCarPrice: result[1].valor,
            originalSuvPrice: result[2].valor,
            originalVanPrice: result[3].valor,
          },
        ]);
      });
    });
  };
}

export function saveTender(request) {
  return (dispatch, getState) => {
    let service = getState().service;
    service = {
      nomeCliente: getState().forms['CUSTOMER_FORM'].nome,
      telefone: getState().forms['CUSTOMER_FORM'].telefoneCelular,
      marcaVeiculo: service.veiculoCliente.marca,
      modeloVeiculo: service.veiculoCliente.modelo,
      tipoVeiculo: service.veiculoCliente.tipoVeiculo,
      enderecoOrigem: service.enderecoOrigem,
      enderecoDestino: service.enderecoDestino,
      tipoServico: service.tipoServico,
      situacaoVeiculo: {
        rodasTravadas: service.rodasTravadas,
        semRodas: service.semRodas,
        capotado: service.capotado,
        garagem: service.garagem,
        blindado: service.blindado,
        quantidadeRodasTravadas: service.quantidadeRodasTravadas,
      },
      valorCorrida: service.price,
      codigoEmpresa: getState().service.company.value || service.company,
    };
    doPost('solicitations/orcamento', service).then((resp) =>
      dispatch([{type: 'TENDER_ID_FETCHED', payload: resp.orcamento.id}, getTenderList()]),
    );
  };
}

export function getTenderList(limit = 10) {
  return (dispatch, getState) => {
    dispatch({type: 'TENDER_LIST_FETCHING', payload: true});
    doGet('solicitations/orcamentos?_limit=' + limit)
      .then((resp) => {
        dispatch([
          {type: 'TENDER_LIST_FETCHED', payload: resp.listOrcamentos || []},
          {type: 'TENDER_LIST_FETCHING', payload: false},
        ]);
      })
      .catch((e) => {
        toastError('Erro ao carregar orçamentos');
        dispatch({type: 'TENDER_LIST_FETCHING', payload: false});
        console.log(e);
      });
  };
}

export function startMonitoringTender() {
  return (dispatch) => {
    dispatch(
      startTimer('TENDERTIMER', 30000, () => {
        dispatch(getTenderList());
      }),
    );
  };
}

export function getPriceAndTimePreview(request_info, autocomplete, auth) {
  return (dispatch, getState) => {
    getAllPricePrediction(request_info, auth).then((promisses) => {
      Promise.all(promisses)
        .then((result) => {
          const text = result
            .map((value) => {
              return `<h3>${value.tipoVeiculo} : R$${value.valor} &nbsp; ${
                value.tempoEspera
              } - ${parseInt(value.tempoEspera * 1.3)} minutos </h3> `;
            })
            .join('');

          confirmDialog(
            {
              title: text,
              text: `<span style="color:#F8BB86">Complete os dados para solicitar o atendimento.<span style="color:#F8BB86">`,
              type: 'success',
              input: 'text',
              what: autocomplete,
              html: true,
              confirmButtonColor: '#25f4af',
              confirmButtonText: 'Fechar',
              showLoaderOnConfirm: true,
              showCancelButton: false,
            },
            () => null,
            () => {
              close();
            },
          );
        })
        .catch((error) => {
          dispatch({type: SERVICE_ERROR, message: error.message});
          confirmDialog(
            {
              title: `<h2>${error.message}</h2>`,
              type: 'warning',
              html: true,
              confirmButtonColor: '#25f4af',
              confirmButtonText: 'Fechar',
              showLoaderOnConfirm: true,
              showCancelButton: false,
            },
            () => {
              close();
            },
          );
        });
    });
  };
}
export function getPriceAndTimePredictionFormExecution(serviceInfo) {
  return (dispatch, getState) => {
    const service = serviceInfo ? serviceInfo : getState().service;
    getPricePrediction(service, getState().account.auth)
      .then((result) => {
        dispatch({
          type: LOAD_PRICES,
          price: preparedPrice(result.valor, getState().service.sliderValue),
          time: result.tempoEspera,
          cupomValido: result.cupomValido,
        });
        dispatch({type: 'SET_ORIGINAL_PRICE', originalPrice: result.valor});
      })
      .catch((error) => {
        dispatch({type: SERVICE_ERROR, message: error.message});
      });
  };
}

export function getPriceAndTimePredictionForm(request_info, auth) {
  return (dispatch, getState) => {
    getPricePrediction(request_info, auth)
      .then((result) => {
        dispatch({
          type: LOAD_PRICES,
          price: preparedPrice(result.valor, getState().service.sliderValue),
          time: result.tempoEspera,
          cupomValido: result.cupomValido,
        });
        dispatch({type: 'SET_ORIGINAL_PRICE', originalPrice: result.valor});
      })
      .catch((error) => {
        dispatch({type: SERVICE_ERROR, message: error.message});
      });
  };
}

export function getPriceAndTimePredictionModal(request_info, auth, sliderValue) {
  return new Promise((resolve, reject) => {
    getPricePrediction(request_info, auth)
      .then((result) => {
        const data = {
          price: preparedPrice(result.valor, sliderValue),
          time: result.tempoEspera,
          cupomValido: result.cupomValido,
          originalPrice: result.valor,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
}
function preparedPrice(price, value) {
  return parseFloat(price + price * (value / 100)).toFixed(0);
}

export function clearPrice() {
  return (dispatch) => {
    dispatch({type: LOAD_PRICES, price: 0, time: 0});
    dispatch({type: 'SET_ORIGINAL_PRICE', originalPrice: 0});
  };
}
export function clearAllPrices() {
  return (dispatch) => {
    dispatch([
      {
        type: 'ALL_PRICES_FETCHED',
        carPrice: 0,
        suvPrice: 0,
        vanPrice: 0,
        avgTime: 0,
      },
      {
        type: 'ALL_ORIGINAL_PRICES_FETCHED',
        originalCarPrice: 0,
        originalSuvPrice: 0,
        originalVanPrice: 0,
      },
    ]);
  };
}

export function clearErrorMessage() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR_MESSAGE,
    });
  };
}

export function onInformArrival(pressSolicitation, onFieldChange, event) {
  return (dispatch, getState) => {
    onFieldChange({
      target: {
        id: `agendado`,
        value: true,
      },
    });

    swal(
      {
        title: 'Agendar Atendimento',
        text: `* A hora informada deve ser pelo menos 1h antes do horário para chegar ao cliente.
        
        Ex: Preciso que chegue 15h, então agendarei para 14h`,
        type: 'input',
        inputType: 'datetime-local',
        inputValue: moment().local().format('YYYY-MM-DDTHH:mm'),
        showCancelButton: true,
        closeOnConfirm: false,
        animation: 'slide-from-top',
        //showLoaderOnConfirm: true
      },
      (inputValue) => {
        if (inputValue === false) return false;

        if (inputValue === '') {
          swal.showInputError('Por favor selecione uma data!');
          return false;
        }
        onFieldChange({
          target: {
            id: `dataAgendada`,
            value: moment(inputValue).format('YYYY-MM-DD HH:mm:ss'),
          },
        });
        //swal("Sucesso!", "Agendamento criado em: " + moment(inputValue).format('YYYY-MM-DD HH:mm:ss'), "success");
        pressSolicitation(event);
      },
    );
  };
}

export function onFieldChange(field, value) {
  return {type: SERVICE_FORM_UPDATED, name: field, value: value};
}

export function showPanel(panelId) {
  return {type: SHOW_PANEL, value: panelId};
}

export function clearServiceState() {
  return {type: CLEAR_SERVICE_STATE};
}

export function clearSchedule() {
  return {type: CLEAR_SERVICE_SCHEDULE};
}

export function onCustomerSelect(customer) {
  return {type: SERVICE_CUSTOMER_SELECTED, cliente: customer};
}

export function onVehicleSelect(vehicle) {
  return {type: SERVICE_VEHICLE_SELECTED, veiculo: vehicle};
}

export function doServiceChange(newKey) {
  return {type: SERVICE_CHANGED, value: newKey};
}

export function onAddressSelect() {
  return {type: SERVICE_SHOW_MAP};
}

export function setAddressFocus() {
  return {type: SERVICE_ADDRESS_FOCUS};
}

export const CLEAR_SERVICE_STATE = 'CLEAR_SERVICE_STATE';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const CLEAR_SERVICE_SCHEDULE = 'CLEAR_SERVICE_SCHEDULE';

export const SERVICE_CUSTOMER_SELECTED = 'SERVICE_CUSTOMER_SELECTED';
export const SERVICE_VEHICLE_SELECTED = 'SERVICE_VEHICLE_SELECTED';

export const SERVICE_CHANGED = 'SERVICE_CHANGED';
export const SERVICE_FORM_UPDATED = 'SERVICE_FORM_UPDATED';
export const SERVICE_SHOW_MAP = 'SERVICE_SHOW_MAP';
export const SERVICE_ADDRESS_FOCUS = 'SERVICE_ADDRESS_FOCUS';

export const SERVICE_ERROR = 'SERVICE_ERROR';
export const SERVICE_CREATED = 'SERVICE_CREATED';
export const SERVICE_FETCHING = 'SERVICE_FETCHING';
export const SHOW_PANEL = 'SHOW_PANEL';
export const LOAD_PRICES = 'LOAD_PRICES';
export const SHOW_MOTIVO_PATIO = 'SHOW_MOTIVO_PATIO';
export const UPDATE_MOTIVO_PATIO = 'UPDATE_MOTIVO_PATIO';
