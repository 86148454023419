import {push} from 'react-router-redux';
import axios from 'axios';

import {toastSuccess, toastError} from './toast_actions';
import {stopAllTimers} from './timer_actions';
import {BASE_URL, V2_APP_URL} from '../config';
import {doCloseModal} from '../components/common/CustomModal';
import {postLogin, putResetPassword, putChangePassword} from '../api/login_helper';

export function checkLogin() {
  return (dispatch, getState) => {
    let user_info = localStorage.getItem('user_info');
    if (user_info) {
      dispatch({type: LOGIN_DONE, user_info: JSON.parse(user_info)});
    } else {
      if (getState().routing.locationBeforeTransitions.pathname !== '/registerCompany')
        dispatch(doLogout());
    }
  };
}

export function loginFormUpdated(field, value) {
  localStorage.setItem(field, value);
  return {type: LOGIN_FORM_UPDATED, name: field, value: value};
}

export function doLogin() {
  return (dispatch) => {
    postLogin(localStorage.getItem('username'), localStorage.getItem('password'))
      .then((user_info) => {
        dispatch(loginDone(user_info));
      })
      .catch((error) => {
        dispatch({type: LOGIN_ERROR, message: 'Usuário ou senha incorretos'});
      });
  };
}
export function goToRegisterCompany() {
  return (dispatch) => {
    dispatch(push('/registerCompany'));
  };
}
export function loginDone(user_info) {
  return (dispatch) => {
    const authDateExpiration = new Date();
    authDateExpiration.setHours(authDateExpiration.getHours() + 4);

    const newUserInfo = {...user_info, authDateExpiration};

    localStorage.setItem('user_info', JSON.stringify(newUserInfo));

    dispatch({type: LOGIN_DONE, user_info: newUserInfo});
    window.location.href = `${V2_APP_URL}dashboard`;
  };
}

export function doLogout() {
  return (dispatch) => {
    localStorage.clear();

    dispatch({type: LOGOUT});
    dispatch(stopAllTimers());
  };
}

export function forgotPassword() {
  return (dispatch) => {
    dispatch(push('/forgotPassword'));
  };
}

export function sendNewPassword() {
  return (dispatch, getState) => {
    putResetPassword(getState().login.email)
      .then((response) => {
        if (!response.sucesso) throw response.mensagemRetorno;
        dispatch(push('/login'));
        dispatch({
          type: LOGIN_SUCCESS,
          message: 'A nova senha foi enviada para seu e-mail!',
        });
      })
      .catch((error) => {
        dispatch({type: LOGIN_ERROR, message: error.message});
      });
  };
}

export function changePassword(senhaAtual, novaSenha, doAfterSuccess = () => {}) {
  return (dispatch, getState) => {
    putChangePassword(senhaAtual, novaSenha)
      .then((response) => {
        doAfterSuccess();
        localStorage.setItem('password', novaSenha);
        toastSuccess(response.mensagemRetorno);
      })
      .catch((error) => {
        dispatch({type: LOGIN_ERROR, message: error.message});
      });
  };
}

export function changeProfileImage(image) {
  return (dispatch) => {
    if (!image) return;
    let formData = new FormData();
    formData.append('image', image, image.name);
    axios
      .put(BASE_URL + 'users/changePhoto', formData, {
        headers: {
          'Content-Type': 'multipart/mixed;boundary=B0EC8D07-EBF1-4EA7-966C-E492A9F2C36E',
        },
        auth: {
          username: localStorage.getItem('username'),
          password: localStorage.getItem('password'),
        },
      })
      .then((resp) => {
        console.log(resp);
        dispatch([doLogin(), doCloseModal('ADD_PROFILE_IMAGE')]);
      })
      .catch((e) => {
        toastError('Erro ao enviar a foto');
      });
  };
}

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_DONE = 'LOGIN_DONE';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FORM_UPDATED = 'LOGIN_FORM_UPDATED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
