import React from 'react'

const FloatingInfo = ({enderecoOrigem, enderecoDestino, tipoServico }) => {

    return (
        <div className="widget-text-box" style={{paddingBottom: '0'}}>
            <div style={{ width: '100%', clear: 'both' }}>
                <div >
                    <div className="text-center" style={{ float: 'left', minWidth: '45px' }}>
                        <i className="fa fa-location-arrow fa-lg" style={{ lineHeight: '20px' }}></i>
                    </div>
                    <address>
                        <p>{enderecoOrigem ? enderecoOrigem : 'Preencha o endereço de origem...'}</p>
                    </address>
                </div>
            </div>
            <div style={{ width: '100%', clear: 'both', display: tipoServico !== 'REBOQUE' ? `none` : `block` }}>
                <div >
                    <div className="text-center" style={{ float: 'left', minWidth: '45px' }}>
                        <i className="fa fa-flag-checkered fa-lg" style={{ lineHeight: '20px' }}></i>
                    </div>
                    <address>
                        <p>{enderecoDestino ? enderecoDestino : 'Preencha o endereço de destino...'}</p>
                    </address>
                </div>
            </div>
        </div>

    )
}


export default FloatingInfo