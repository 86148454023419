import request from 'superagent'
import {doPost, doPut} from './base/base_api_helper'

export function postLogin(username, password) {
  return doPost("users/loginAdmin", {
    username: username,
    password: password
  })
}

export function putResetPassword(userEmail) {
  return doPut(`users/${userEmail}/resetPassword`, {})
}

export function putChangePassword(senhaAtual, novaSenha) {
  return doPut(`users/changePassword`, {
    senhaAtual: senhaAtual,
    novaSenha: novaSenha
  })
}
