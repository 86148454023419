import {store} from '..';

import {
  USUARIO_TIPO_REBOQUEME,
  USUARIO_TIPO_BASE,
  USUARIO_TIPO_EMPRESA,
  APP_PROFILES,
  CODIGO_TEGMA,
  CODIGO_DAF,
  CODIGO_KAVAK,
  CODIGO_MAPFRE,
  CODIGO_LOCALIZA,
  CODIGO_KOVI,
  CODIGO_PORTO_SEGURO,
} from '../reducers/constants';

let login = null;
let user_info = null;
let profiles = [];

const updateStoreState = () => {
  user_info = JSON.parse(localStorage.getItem('user_info'));
  if (store) {
    login = store.getState().login;

    user_info = login.user_info;

    profiles = login.profiles;
  }
};

export const hasReboqueMeProfile = () => {
  updateStoreState();
  if (!user_info) return false;

  if (user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME) {
    return true;
  }

  return false;
};

export const hasMAPFREProfile = () => {
  updateStoreState();
  if (!user_info) return false;

  if (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA &&
    user_info.empresa.codigo === CODIGO_MAPFRE
  ) {
    return true;
  }

  return false;
};

export const hasPortoSeguroProfile = () => {
  updateStoreState();
  if (!user_info) return false;

  if (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA &&
    user_info.empresa.codigo === CODIGO_PORTO_SEGURO
  ) {
    return true;
  }

  return false;
};

export const hasLocalizaProfile = () => {
  updateStoreState();
  if (!user_info) return false;

  if (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA &&
    user_info.empresa.codigo === CODIGO_LOCALIZA
  ) {
    return true;
  }

  return false;
};

export const hasKoviProfile = () => {
  updateStoreState();
  if (!user_info) return false;

  if (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA &&
    user_info.empresa.codigo === CODIGO_KOVI
  ) {
    return true;
  }

  return false;
};

export const isSoonOrLocalizaProfile = (companyID) => {
  updateStoreState();
  if (!user_info) return false;

  if (
    (user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME ||
      user_info.empresa.codigo === CODIGO_LOCALIZA) &&
    companyID === CODIGO_LOCALIZA
  ) {
    return true;
  }

  return false;
};

export const hasSoonProfileAndLocalizaAccess = (companyID) => {
  updateStoreState();
  if (!user_info) return false;

  if (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME &&
    companyID === CODIGO_LOCALIZA
  ) {
    return true;
  }

  return false;
};

export const hasOnlyCompanyMonitoringProfile = () => {
  updateStoreState();
  if (!user_info) return false;

  return (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA &&
    user_info.usuario.perfis.length === 1 &&
    user_info.usuario.perfis.some(({id}) => id === APP_PROFILES.CompanyMonitoramento)
  );
};

export const hasTegmaProfile = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA &&
    user_info.empresa.codigo === CODIGO_TEGMA
  ) {
    return true;
  }

  return false;
};

export const hasKavakProfile = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA &&
    user_info.empresa.codigo === CODIGO_KAVAK
  ) {
    return true;
  }

  return false;
};

export const isFromCompany = (companyCode) => {
  updateStoreState();

  if (!user_info) return false;

  if (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA &&
    user_info.empresa.codigo === companyCode
  ) {
    return true;
  }

  return false;
};

export const hasDafProfile = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA &&
    user_info.empresa.codigo === CODIGO_DAF
  ) {
    return true;
  }

  return false;
};

export const hasDafTvProfile = () => {
  updateStoreState();
  if (!user_info) return false;

  if (user_info.usuario.login === 'DAFPGRFPP02088') {
    return true;
  }

  return false;
};

export const hasCompanyProfile = () => {
  updateStoreState();

  if (!user_info) return false;

  if (user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA) {
    return true;
  }

  return false;
};

export const hasBaseProfile = () => {
  updateStoreState();

  if (!user_info) return false;

  if (user_info.usuario.tipoUsuario === USUARIO_TIPO_BASE) {
    return true;
  }

  return false;
};

export const hasAccessToPublicHistoryPost = () => {
  updateStoreState();

  if (!user_info) return false;

  const autorizedProfiles = [
    APP_PROFILES.Administrador,
    APP_PROFILES.FinanceiroSoon,
    APP_PROFILES.ManagerAtendimento,
    APP_PROFILES.ManagerAcionamento,
    APP_PROFILES.SupervisorRede,
    APP_PROFILES.Monitoramento,
    APP_PROFILES.ManagerMonitoramento,
  ];

  if (
    user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME &&
    user_info.usuario.perfis.some(({id}) => autorizedProfiles.includes(id))
  ) {
    return true;
  }

  return false;
};

export const hasContracts = (contractArray) => {
  updateStoreState();

  if (!user_info) return false;

  const contracts = new Set(contractArray);
  const {empresa} = user_info;
  if (empresa) {
    return empresa.tipoContrato && contracts.has(empresa.tipoContrato.codigo);
  }
  return false;
};

export const hasProfiles = (profilesArray) => {
  updateStoreState();

  if (!user_info) return false;

  const profilesSet = new Set(profilesArray);
  if (profiles) {
    return profiles.some((profile) => profilesSet.has(profile.id));
  }
  return false;
};

export const hasCustomerAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([
      APP_PROFILES.FinanceiroSoon,
      APP_PROFILES.Administrador,
      APP_PROFILES.ManagerAtendimento,
    ])
  ) {
    return true;
  }

  if (
    hasCompanyProfile() &&
    hasContracts(['CONTRATOPORGESTAO', 'CONTRATOPORFREQUENCIA']) &&
    hasProfiles([APP_PROFILES.CompanyManager])
  ) {
    return true;
  }

  return false;
};

export const hasAssistanceAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (hasProfiles([APP_PROFILES.FinanceiroSoon, APP_PROFILES.Atendente])) {
    return true;
  }

  if (
    hasCompanyProfile() &&
    hasProfiles([APP_PROFILES.CompanyManager, APP_PROFILES.Operador]) &&
    hasContracts(['CONTRATOPORGESTAO', 'CONTRATOPORFREQUENCIA'])
  ) {
    return true;
  }

  return false;
};

export const hasMonitoringAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([
      APP_PROFILES.Administrador,
      APP_PROFILES.Monitoramento,
      APP_PROFILES.ManagerAtendimento,
    ])
  ) {
    return true;
  }
  return false;
};

export const hasChangeAlterAcess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([APP_PROFILES.Administrador, APP_PROFILES.ManagerAtendimento])
  ) {
    return true;
  }
  return false;
};

export const hasSolicitationAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (hasReboqueMeProfile() && hasProfiles([APP_PROFILES.Atendente])) {
    return true;
  }

  if (hasOnlyCompanyMonitoringProfile()) {
    return false;
  }

  if (hasCompanyProfile() && hasContracts(['CONTRATOPORDEMANDA'])) {
    return true;
  }

  return false;
};

export const hasBasesAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([
      APP_PROFILES.AnalistaRede,
      APP_PROFILES.SupervisorRede,
      APP_PROFILES.FinanceiroSoon,
    ])
  ) {
    return true;
  }

  return false;
};

export const hasChatAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  const {possuiAcessoChat} = user_info.usuario;
  if (
    (hasReboqueMeProfile() || (hasCompanyProfile() && !process.env.REACT_APP_IS_HOMOLOG)) &&
    possuiAcessoChat
  ) {
    return true;
  }
  return false;
};

export const hasLocalsAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([APP_PROFILES.ManagerAtendimento, APP_PROFILES.Administrador])
  ) {
    return true;
  }

  if (
    hasCompanyProfile() &&
    hasContracts(['CONTRATOPORGESTAO', 'CONTRATOPORFREQUENCIA']) &&
    hasProfiles([APP_PROFILES.CompanyManager])
  ) {
    return true;
  }

  return false;
};

export const hasAcionamentoProfile = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([APP_PROFILES.ManagerAcionamento, APP_PROFILES.Acionamento])
  ) {
    return true;
  }

  return false;
};

export const hasActivationAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([
      APP_PROFILES.AnalistaRede,
      APP_PROFILES.SupervisorRede,
      APP_PROFILES.Administrador,
      APP_PROFILES.Acionamento,
      APP_PROFILES.ManagerAcionamento,
      APP_PROFILES.Monitoramento,
    ])
  ) {
    return true;
  }

  return false;
};
export const hasSsPendingAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([
      APP_PROFILES.Administrador,
      APP_PROFILES.ManagerAcionamento,
      APP_PROFILES.ManagerAtendimento,
      APP_PROFILES.ManagerMonitoramento,
      APP_PROFILES.CompanyManager,
      APP_PROFILES.Monitoramento,
      APP_PROFILES.Atendente,
    ])
  ) {
    return true;
  }

  return false;
};

export const hasNewSolicitationModalAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (hasReboqueMeProfile() && hasProfiles([APP_PROFILES.Tester])) {
    return true;
  }

  return false;
};

export const hasAnaliseDeRedeAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([
      APP_PROFILES.AnalistaRede,
      APP_PROFILES.SupervisorRede,
      APP_PROFILES.Administrador,
    ])
  ) {
    return true;
  }

  return false;
};

export const hasAnaliseDeRedeBaseAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (hasBaseProfile()) {
    return true;
  }

  return false;
};

export const hasCreateLeadAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([
      APP_PROFILES.Administrador,
      APP_PROFILES.ManagerAcionamento,
      APP_PROFILES.Acionamento,
      APP_PROFILES.SupervisorRede,
      APP_PROFILES.AnalistaRede,
      APP_PROFILES.Monitoramento,
      APP_PROFILES.ManagerMonitoramento,
    ])
  ) {
    return true;
  }

  return false;
};

export const hasInvoicesAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (hasReboqueMeProfile() && hasProfiles([APP_PROFILES.FinanceiroSoon])) {
    return true;
  }

  if (hasCompanyProfile() && hasProfiles([APP_PROFILES.FinanceiroEmpresa])) {
    return true;
  }

  if (hasBaseProfile()) {
    return true;
  }

  return false;
};

export const hasFechamentoAndPendenciasAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (hasReboqueMeProfile() && hasProfiles([APP_PROFILES.FinanceiroSoon])) {
    return true;
  }

  return false;
};

export const hasDocumentsAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([
      APP_PROFILES.AnalistaRede,
      APP_PROFILES.SupervisorRede,
      APP_PROFILES.ManagerAcionamento,
      APP_PROFILES.ManagerAtendimento,
      APP_PROFILES.ManagerMonitoramento,
      APP_PROFILES.FinanceiroSoon,
      APP_PROFILES.Administrador,
      APP_PROFILES.Monitoramento,
    ])
  ) {
    return true;
  }

  return false;
};

export const canAccessEditObservation = () => {
  updateStoreState();

  if (!user_info) return false;

  if (
    hasReboqueMeProfile() &&
    hasProfiles([
      APP_PROFILES.AnalistaRede,
      APP_PROFILES.SupervisorRede,
      APP_PROFILES.Administrador,
      APP_PROFILES.ManagerMonitoramento,
      APP_PROFILES.Monitoramento,
    ])
  ) {
    return true;
  }

  return false;
};

export const hasManagerAcess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (!hasReboqueMeProfile()) {
    return true;
  }

  if (
    hasReboqueMeProfile() &&
    hasProfiles([
      APP_PROFILES.SupervisorRede,
      APP_PROFILES.ManagerAcionamento,
      APP_PROFILES.ManagerAtendimento,
      APP_PROFILES.ManagerMonitoramento,
      APP_PROFILES.CompanyManager,
      APP_PROFILES.Administrador,
      APP_PROFILES.Monitoramento,
      APP_PROFILES.Acionamento,
    ])
  ) {
    return true;
  }

  return false;
};

export const hasAdminAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (hasReboqueMeProfile() && hasProfiles([APP_PROFILES.Administrador])) {
    return true;
  }

  return false;
};

export const hasVehicleHistoryAccess = () => {
  updateStoreState();

  if (!user_info) return false;

  if (hasReboqueMeProfile() || hasCompanyProfile()) {
    return true;
  }

  return false;
};
