import {add, result} from 'lodash';

import {toastError, toastSuccess} from './toast_actions';
import {onFormError, onFormSuccess} from './form_actions';
import {changeBaseAddress} from './base_actions';
import {
  getCity,
  getArea,
  getStreetNumber,
  getCountry,
  getState as getProvince,
  getPostalCode,
} from '../utils/address_helper';
import {getAddressSuggestions} from '../api/maps/base_maps_helper';
import {
  getFavoriteDestinations,
  createFavoriteDestination,
  createCompaniesFavoriteDestination,
  deleteFavoriteDestination,
  getClosestPartnersCall,
  updateDealership,
} from '../api/address_helper';

export function onAddressChange(addressField, address, fromSendNewObservation = false) {
  return (dispatch) => {
    if (address) {
      if (!fromSendNewObservation) {
        if (address.type === 'favorite') {
          address.city = address.cidade;
          address.area = address.bairro;
          address.street_number = address.numero;
          address.label = address.logradouro;
        } else {
          address.city = getCity(address);
          address.area = getArea(address);
          address.street_number = getStreetNumber(address);
          address.country = getCountry(address);
          address.province = getProvince(address);
          address.postal_code = getPostalCode(address);
        }
        if (addressField === 'enderecoOrigem') dispatch(getClosestPartners(address.location));
        if (addressField === 'baseEndereco') {
          address.city = getCity(address);
          address.area = getArea(address);
          address.street_number = getStreetNumber(address);
          address.country = getCountry(address);
          address.province = getProvince(address);
          address.postal_code = getPostalCode(address);

          dispatch(changeBaseAddress(address));
        }
      }

      dispatch({
        type: MAP_ADDRESS_CHANGED,
        field: addressField,
        value: address || {},
      });
    }
  };
}

export function pureAddressChange(
  addressField,
  logradouro,
  latitude,
  longitude,
  numero,
  bairro,
  cidade,
) {
  return (dispatch) =>
    dispatch({
      type: MAP_ADDRESS_CHANGED,
      field: addressField,
      value: {
        label: logradouro,
        street_number: numero,
        location: {
          lat: latitude,
          lng: longitude,
        },
        area: bairro,
        city: cidade,
      },
    });
}

export function getClosestPartners(location) {
  return (dispatch) => {
    getClosestPartnersCall(location).then((resp) =>
      dispatch({type: 'MAP_CLOSEST_PARTNERS', partners: resp.partners}),
    );
    //.catch(e=>console.log("Erro ao localizar parceiros próxmimos", e))
  };
}

export function clearAddress(addressField) {
  return {
    type: CLEAR_ADDRESS_FIELD,
    field: addressField,
    value: {},
  };
}

export function loadFavoriteDestiantionList(companyId) {
  return (dispatch) => {
    getFavoriteDestinations(companyId)
      .then((results) => {
        dispatch({
          type: FAVORITE_DESTINATIONS_LIST_CHANGED,
          value: results.listFavoriteDestinations,
        });
      })
      .catch((error) => {
        console.log('Erro ao carregar lista de Endereços Favoritos: ' + error.message);
      });
  };
}
export function changeFavoriteDestination(attendanceId, companyId) {
  return updateDealership(attendanceId, companyId)
    .then((results) => {
      toastSuccess(results.message);
    })
    .catch((error) => {
      throw error;
    });
}

export function createFavoriteAddress(formContext, doAfterSuccess) {
  return (dispatch, getState) => {
    const destinationFormData = getState().forms[formContext];
    const addressInputData = getState().address[formContext];
    const tipoEndereco = getState().address.tipoEndereco;

    // console.log({descricao: destinationFormData.descricao, endereco: {...addressInputData}, tipoEndereco})
    // if( serviceState.tipoServico == 'REBOQUE' &&
    //     (!serviceState.enderecoDestino || !serviceState.enderecoDestino.latitude)) {
    //   dispatch( onFormError(formContext, '' || error) )
    //   return
    // }

    const company = destinationFormData.company;

    createCompaniesFavoriteDestination({
      email: destinationFormData.email ? destinationFormData.email : '',
      descricao: destinationFormData.descricao,
      endereco: {...addressInputData},
      tipoEndereco,
      contato: destinationFormData.contact1,
      telefoneContato: destinationFormData.telefone1,
      contato2: destinationFormData.contact2,
      telefoneContato2: destinationFormData.telefone2,
      contato3: destinationFormData.contact3,
      telefoneContato3: destinationFormData.telefone3,
      codigoEmpresa: company ? company.value : undefined,
    })
      .then((result) => {
        dispatch(onFormSuccess(formContext));
        doAfterSuccess(result);
      })
      .catch((error) => {
        dispatch(onFormError(formContext, error.message || error));
      });
  };
}

export function deleteFavoriteAddress(address, doAfterSuccess) {
  return (dispatch, getState) => {
    deleteFavoriteDestination(address.codigo)
      .then((result) => {
        dispatch(loadFavoriteDestiantionList());
        doAfterSuccess();
      })
      .catch((error) => {
        toastError(error.message || error);
      });
  };
}

export function updateFavoriteAddress() {
  return null;
}

export function onDestinationFormChange(addressField, address) {
  if (address) {
    address.city = getCity(address);
    address.area = getArea(address);
    address.street_number = getStreetNumber(address);
  }
  return {
    type: DESTINATION_FORM_CHANGED,
    field: addressField,
    value: address,
  };
}

export function doTypeChange(newKey) {
  return {type: ADDREESS_TYPE_CHANGED, value: newKey};
}

export const FAVORITE_DESTINATIONS_LIST_CHANGED = 'FAVORITE_DESTINATIONS_LIST_CHANGED';
export const MAP_ADDRESS_CHANGED = 'MAP_ADDRESS_CHANGED';
export const DESTINATION_FORM_CHANGED = 'DESTINATION_FORM_CHANGED';
export const ADDREESS_TYPE_CHANGED = 'ADDREESS_TYPE_CHANGED';
export const CLEAR_ADDRESS_FIELD = `CLEAR_ADDRESS_FIELD`;
