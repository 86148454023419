import React from "react";
import Popover, { ArrowContainer } from "react-tiny-popover";

const ServiceFloatIndividual = ({
  time,
  price,
  service,
  copy,
  tenderPrice,
  isTegmaUser,
  onChange,
}) => {
  return (
    <div
      className="widget-head-color-box navy-bg p-lg text-center"
      style={{ padding: `10px`, marginTop: `0px` }}
    >
      <div className="m-b-md">
        <h2 className="font-bold no-margins">{service}</h2>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <div
            className="widget style1 navy-bg"
            style={{
              paddingTop: `0px`,
              paddingBottom: `0px`,
              paddingRight: `0px`,
            }}
          >
            <div className="row vertical-align">
              <div className="col-xs-3">
                <i className="fas fa-money-bill-alt fa-2x" />
              </div>
              <Popover
                isOpen={!!tenderPrice}
                position={"top"}
                content={({ position, targetRect, popoverRect }) => (
                  <ArrowContainer
                    position={
                      position // preferred position // if you'd like an arrow, you can import the ArrowContainer!
                    }
                    targetRect={targetRect}
                    popoverRect={popoverRect}
                    arrowColor={"white"}
                    arrowSize={5}
                    arrowStyle={{ opacity: 0.8 }}
                  >
                    <div
                      style={{
                        backgroundColor: "white",
                        opacity: 0.8,
                        borderRadius: "4px",
                        padding: "2px",
                      }}
                    >
                      Orçamento: R${tenderPrice},00
                    </div>
                  </ArrowContainer>
                )}
              >
                {isTegmaUser ? (
                  <div className="col-xs-9">
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => onChange(e.target.value)}
                      value={price}
                      style={{
                        backgroundColor: "#5cd3ff",
                        border: "none",
                        fontSize: "30px",
                        lineHeight: "33px",
                        fontWeight: 100,
                        textAlign: "center",
                        textSizeAdjust: "100%",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="col-xs-9 copy-btn"
                    data-tip="Copiar"
                    onClick={() => copy(price)}
                  >
                    <h2 className="">{price !== 0 ? `${price},00` : `-`}</h2>
                  </div>
                )}
              </Popover>
            </div>
          </div>
        </div>
        <div className="col-xs-6">
          <div
            className="widget style1 navy-bg"
            style={{
              paddingTop: `0px`,
              paddingBottom: `0px`,
              paddingLeft: `0px`,
            }}
          >
            <div className="row vertical-align">
              <div className="col-xs-3">
                <i className="fas fa-clock fa-2x" />
              </div>
              <div className="col-xs-9">
                <h2 className="">
                  {time !== 0 ? `${time} - ${parseInt(time * 1.3)}` : `-`}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceFloatIndividual;
