import React, {Component} from 'react';
import OptionChooser from '../components/common/OptionChooser';
import SolicitationMap from '../components/common/SolicitationMap';
import CustomerAutoComplete from '../components/containers/CustomerAutoCompleteStateful';
import VehicleAutoComplete from '../components/containers/VehicleAutoCompleteStateful';
import AddressAutoComplete from '../components/containers/AddressAutoComplete';
import {getAccountList} from '../actions/accountActions';
import DateTime from 'react-datetime';
import moment from 'moment';
import {selectCustomStyle} from '../utils/SelectCustomStyle';
import {updateMotivoPatio, sendSolicitation} from '../actions/service_actions';
//import VehicleSeePriceAutoComplete from '../components/containers/VehicleSeePriceAutoCompleteStateful'
import CustomerForm from '../components/forms/CustomerFormStateful';
import VehicleForm, {
  VEHICLE_FORM_CUSTOMER_INPUT,
  MODEL_AUTOCOMPLETE,
  VEHICLE_FORM_MODEL_INPUT,
  VEHICLE_FORM,
} from '../components/forms/VehicleFormStateful';
import DriverForm from '../components/forms/DriverForm';
import VehicleStatusForm from '../components/forms//VehicleStatusForm';
import FindForm from '../components/forms/findMeForm';
import CustomModal, {doCloseModal, doOpenModal} from '../components/common/CustomModal';
import {connect} from 'react-redux';
import {isSoonOrLocalizaProfile} from '../utils/check_permission';
import ReactTooltip from 'react-tooltip';

import __ from 'underscore';

import {loadFavoriteDestiantionList, onAddressChange} from '../actions/address_actions';
import {
  onFieldChange,
  doServiceChange,
  clearServiceState,
  clearPrice,
  clearSchedule,
  clearAllPrices,
  clearErrorMessage,
  setAddressFocus,
  getPriceAndTimePrediction,
  getPriceAndTimePredictionForm,
  getPriceAndTimePreview,
  SERVICE_ERROR,
  onInformArrival,
  getPriceAndTimePredictionFormExecution,
  getAllPricePredictionExecution,
  saveTender,
  getTenderList,
} from '../actions/service_actions';

import {close} from 'sweetalert';
import {confirmDialog, confirmDialogSMS} from '../actions/confirm_actions';
import {showPanel, onCustomerSelect, onVehicleSelect} from '../actions/service_actions';
import {
  createNewCustomer,
  updateExistingCustomer,
  setCustomerInputDetails,
} from '../actions/customer_actions';
import {setMarcaModeloInputDetails} from '../actions/model_autocomplete_actions';
import {setVehicleDetails, updateExistingVehicle} from '../actions/vehicle_actions';
import {onInputCleared, onOptionSelected} from '../actions/autocomplete_actions';
import {
  setEditingMode,
  setReadOnlyMode,
  setFormValues,
  clearFormValues,
  onFormFieldChange,
} from '../actions/form_actions';

import {Tabs, Tab} from 'react-bootstrap';

import _ from 'lodash';

import Toggle from 'react-toggle-button';

import {sendSmsFindMe} from '../api/solicitation_helper';
import {
  toastWarning,
  customToastError,
  toastSuccess,
  toastError,
} from '../actions/toast_actions';
import {
  CODIGO_JUSTOS,
  CODIGO_LOCALIZA,
  CODIGO_LOCALIZA_PESADOS,
  CODIGO_LOOVI,
  PROTOCOL_CHIP_VISIBLE_FOR,
} from '../reducers/constants';

import Select from 'react-select';
import FastButton from '../components/common/fastButton';
import ModelAutoCompleteStateful from '../components/containers/ModelAutoCompleteStateful';
import ServiceFloatingPanel from '../components/common/ServiceFloatingPanel';
import ServiceFloatIndividual from '../components/common/ServiceFloatIndividual';
import FloatingInfo from '../components/common/FloatingInfo';
import PriceSlider from '../components/common/PriceSlider';
import Tender from '../components/tender/Tender';
import {getCompanies, CompaniesContracts} from '../actions/relatorio_actions';
import {isEmpty} from '../components/layouts/Helpers';
import MaskedInput from '../components/common/MaskedInput';
import ObservationForm from '../ObservationForm';
export const PANEL_MAPS = 'PANEL_MAPS';
export const PANEL_CUSTOMER = 'PANEL_CUSTOMER';
export const PANEL_VEHICLE = 'PANEL_VEHICLE';
export const PANEL_STEPS = `PANEL_STEPS`;

const CUSTOMER_AUTOCOMPLETE = 'CUSTOMER_AUTOCOMPLETE';
const VEHICLE_AUTOCOMPLETE = 'VEHICLE_AUTOCOMPLETE';
const CUSTOMER_FORM = 'CUSTOMER_FORM';

const TEGMA_CODE = '2457545233412396244';

const MODAL_ANOTHER_DRIVER = 'MODAL_ANOTHER_DRIVER';
const MODAL_VEHICLE_STATUS = 'MODAL_VEHICLE_STATUS';
const MODAL_FIND = 'MODAL_FIND';
const labels = [
  {
    value: 'Ligar para Cliente',
    class: 'primary',
  },
  {
    value: 'Levar máquina de cartão',
    class: 'primary',
  },

  {
    value: 'Pagamento em dinheiro',
    class: 'primary',
  },
  {
    value: 'Cliente vai pagar pedágio',
    class: 'primary',
  },
  {
    value: 'Não cobrar do cliente',
    class: 'warning',
  },
  {
    value: 'Pegar recibo do pedágio',
    class: 'warning',
  },
  {
    value: 'Descarregado',
  },
  {
    value: 'Precisa de patins',
  },
  {
    value: 'Veículo blindado',
  },
  {
    value: 'Rota curta',
  },
  {
    value: 'Checklist com foto',
  },
];

const protocolLabels = [
  {value: 'Desmobilização'},
  {value: 'Inadimplência'},
  {value: 'Apropriação Indébita'},
  {value: 'Multas'},
  {value: 'Perda de Placa'},
  {value: 'Perda de Chave'},
  {value: 'Sinistro'},
  {value: 'Retorno de Sinistro'},
  {value: 'Manutenção'},
  {value: 'Retorno de Manutenção'},
  {value: 'Segurança'},
];

export const Check = () => (
  <svg width="14" height="10" viewBox="0 0 14 11" xmlns="http://www.w3.org/2000/svg">
    <title>switch-check</title>
    <path
      d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
);

export const X = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
    <title>switch-x</title>
    <path
      d="M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
);

class ServiceView extends Component {
  constructor(props) {
    super(props);
    this.toggleStartCallPartner = this.toggleStartCallPartner.bind(this);
    this.toggleStartAddres = this.toggleStartAddres.bind(this);
    this.toggleSendSMSCustomer = this.toggleSendSMSCustomer.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.handleTabsNav = this.handleTabsNav.bind(this);
    this.handleService = this.handleService.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handleObservation = this.handleObservation.bind(this);
    this.handleModelSelected = this.handleModelSelected.bind(this);
    this.updatePrices = this.updatePrices.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.getCopyValues = this.getCopyValues.bind(this);
    this.onTenderItemClick = this.onTenderItemClick.bind(this);
    this.tenderValidation = this.tenderValidation.bind(this);
    this.onFieldChangeProtocolo = this.onFieldChangeProtocolo.bind(this);
    this.escapeRegExp = this.escapeRegExp.bind(this);
    this.verifyCaptcha = this.verifyCaptcha.bind(this);
    this.handleCaptchaInput = this.handleCaptchaInput.bind(this);
    this.state = {
      originalPrice: 0,
      cupom_desconto: false,
      preco_categoria: false,
      callPartner: true,
      sendSMSCustomer: false,
      key: 2,
      accountList: [],
      company: {
        value: null,
        password: null,
      },
      driverFormShow: false,
      modelSelected: false,
      priceCopy: 0,
      freeNav: false,
      currentSelectedCompany: null,
      showMotivoPatio: false,
      motivoPatio: '',
      enderecoDestinoPos: '',
      AddressAfterPatio: true,
      saveAddress: [],
      saveValue: '',
      tipoVeiculo: '',
      num1: Math.floor(Math.random() * 100) + 1,
      num2: Math.floor(Math.random() * 100) + 1,
      userAnswer: '',
      captchaValid: false,
    };
  }
  componentWillUnmount() {
    this.props.doClearAll();
    doCloseModal('CONTEXT_MOTIVO_PATIO');
  }

  componentDidMount() {
    this.props.loadFavoriteDestiantionList();
    this.focusAddressInput();
    this.props.getAccountList();
    this.setState({
      floatingPanelHeight: this.floatingInfo.clientHeight,
      leftPanelHeight: this.leftPanel.clientHeight,
    });
    this.props.getCompanies();
    if (this.props.profiles[0].id !== 1) {
      this.props.changeCompany(this.props.loginCompany.codigo);
      this.setState({
        currentSelectedCompany: this.props.loginCompany.codigo,
      });
    }
    //this.props.focusOnAddress()

    // Limpando o conteudo apenas se o usuario for admin
    if (this.props.login.user_info.usuario.tipoUsuario === 1) {
      this.handleModelSelected(false);
      this.props.doClearAll();
      this.props.getTenderList();
    }
    this.handleProtocol = this.handleProtocol.bind(this);
  }
  componentWillReceiveProps(nextProps, nextState) {
    const auth = {
      username: this.state.company.value,
      password: this.state.company.password,
    };
    this.setState({accountList: nextProps.accountList});
    this.loadProfilePermissions(nextProps);

    if (
      nextProps.serviceInfo.distance !== this.props.serviceInfo.distance &&
      !_.isEmpty(nextProps.veiculoCliente)
    ) {
      this.props.loadPrice(nextProps.serviceInfo, auth);
    }

    if (
      nextProps.serviceInfo.dataAgendada !== this.props.serviceInfo.dataAgendada &&
      !_.isEmpty(nextProps.veiculoCliente) &&
      !_.isEmpty(nextProps.enderecoOrigem) &&
      (nextProps.tipoServico !== `REBOQUE` || !_.isEmpty(nextProps.enderecoDestino))
    ) {
      this.props.loadPrice(nextProps.serviceInfo, auth);
    }

    if (
      nextProps.serviceInfo.cupom !== this.props.serviceInfo.cupom &&
      nextProps.tipoServico !== `REBOQUE` &&
      nextProps.enderecoOrigem &&
      !_.isEmpty(nextProps.veiculoCliente)
    ) {
      this.props.loadPrice(nextProps.serviceInfo, auth);
    }

    if (
      nextProps.serviceInfo.cupom !== this.props.serviceInfo.cupom &&
      nextProps.tipoServico === `REBOQUE` &&
      nextProps.enderecoOrigem &&
      nextProps.enderecoDestino &&
      !_.isEmpty(nextProps.veiculoCliente)
    ) {
      this.props.loadPrice(nextProps.serviceInfo, auth);
    }

    if (nextProps.errorMessage !== undefined) {
      if (
        nextProps.errorMessage !==
        `Desculpe, não encontramos parceiros disponíveis para o seu endereço.`
      ) {
        this.props.clearError();
        this.props.onErrorSolicitation(nextProps.errorMessage);
      }
    }
    if (
      nextProps.enderecoDestino !== this.props.enderecoDestino ||
      nextProps.enderecoOrigem !== this.props.enderecoOrigem ||
      nextProps.tipoServico !== this.props.tipoServico ||
      nextProps.veiculoCliente !== this.props.veiculoCliente
    ) {
      if (
        nextProps.tipoServico === `REBOQUE` &&
        nextProps.enderecoOrigem &&
        nextProps.enderecoDestino &&
        !_.isEmpty(nextProps.veiculoCliente)
      ) {
        this.props.loadPrice(nextProps.serviceInfo, auth);
      } else if (
        nextProps.tipoServico === `REBOQUE` &&
        nextProps.enderecoOrigem &&
        nextProps.enderecoDestino &&
        _.isEmpty(nextProps.veiculoCliente)
      ) {
        // this.props.getAllTimeAndPrice(nextProps.serviceInfo);
      } else {
        this.clearPrice();
        this.clearAllPrices();
      }
      if (
        nextProps.tipoServico !== `REBOQUE` &&
        nextProps.enderecoOrigem &&
        _.isEmpty(nextProps.veiculoCliente)
      ) {
        this.props.getAllTimeAndPrice(nextProps.serviceInfo);
      } else if (
        nextProps.tipoServico !== `REBOQUE` &&
        nextProps.enderecoOrigem &&
        !_.isEmpty(nextProps.veiculoCliente)
      )
        this.props.loadPrice(nextProps.serviceInfo, auth);
      else {
        this.clearPrice();
        this.clearAllPrices();
      }
    }
    !nextProps.enderecoDestino && nextProps.price > 0 && nextProps.tipoServico === `REBOQUE`
      ? () => {
          this.clearPrice();
          this.clearAllPrices();
        }
      : null;

    !nextProps.enderecoOrigem && nextProps.price > 0
      ? () => {
          this.clearPrice();
          this.clearAllPrices();
        }
      : null;
  }

  verifyCaptcha() {
    const {num1, num2, userAnswer} = this.state;
    return parseInt(userAnswer, 10) === num1 * num2;
  }

  handleCaptchaInput(event) {
    this.setState({userAnswer: event.target.value});
  }

  loadProfilePermissions = (nextProps) => {
    const {profiles} = nextProps;
    if (profiles && profiles[0] && profiles[0].funcionalidades) {
      const preco_categoria = _.find(
        profiles[0].funcionalidades,
        (profile) => profile.descricaoResumida === 'preco_categoria',
      );
      const cupom_desconto = _.find(
        profiles[0].funcionalidades,
        (profile) => profile.descricaoResumida === 'cupom_desconto',
      );
      const enviar_sms = _.find(
        profiles[0].funcionalidades,
        (profile) => profile.descricaoResumida === 'enviar_sms',
      );
      const acionar_manualmente = _.find(
        profiles[0].funcionalidades,
        (profile) => profile.descricaoResumida === 'acionar_manualmente',
      );
      const enviar_sms_localizador = _.find(
        profiles[0].funcionalidades,
        (profile) => profile.descricaoResumida === 'enviar_sms_localizador',
      );
      this.setState({
        preco_categoria,
        cupom_desconto,
        enviar_sms,
        acionar_manualmente,
        enviar_sms_localizador,
      });
    }
  };

  focusVehicleInput() {
    $('#VEHICLE_AUTOCOMPLETE').focus();
  }

  focusCustomerInput() {
    $('#CUSTOMER_AUTOCOMPLETE').focus();
  }

  focusAddressInput() {
    $('#enderecoOrigem').focus();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.accountList !== this.props.accountList) return true;
    if (nextProps.profiles && nextProps.rightPanel === 'PANEL_CUSTOMER') {
      return true;
    }
    if (nextProps.profiles !== this.props.profiles) {
      return true;
    }
    if (nextProps.tipoServico !== this.props.tipoServico) {
      return true;
    }
    if (
      this.props.enderecoOrigem === nextProps.enderecoOrigem &&
      this.props.enderecoDestino !== nextProps.enderecoDestino
    ) {
      return true;
    }
    if (
      this.props.enderecoDestino === nextProps.enderecoDestino &&
      !this.props.enderecoOrigem &&
      nextProps.tipoServico !== `REBOQUE`
    ) {
      return true;
    }
    if (
      this.props.enderecoDestino === nextProps.enderecoDestino &&
      this.props.enderecoOrigem !== nextProps.enderecoOrigem
    ) {
      return true;
    }
    if (
      this.props.enderecoOrigem !== nextProps.enderecoOrigem ||
      this.props.enderecoDestino !== nextProps.enderecoDestino
    ) {
      return true;
    }
    if (this.props.rightPanel !== nextProps.rightPanel) {
      return true;
    }
    if (nextProps.serviceInfo.callPartner !== this.props.serviceInfo.callPartner) {
      return true;
    }
    if (nextProps.serviceInfo.sendSMSCustomer !== this.props.serviceInfo.sendSMSCustomer) {
      return true;
    }
    if (
      nextProps.errorMessage !== undefined &&
      nextProps.errorMessage !== this.props.errorMessage
    ) {
      return true;
    }
    if (
      nextProps.errorMessage === undefined &&
      nextProps.errorMessage !== this.props.errorMessage
    ) {
      return true;
    }
    if (nextState.key !== this.state.key) {
      return true;
    }
    if (nextProps.price !== this.props.price) {
      return true;
    }
    if (nextProps.time !== this.props.time) {
      return true;
    }
    if (nextProps.observacao !== this.props.observacao) return true;
    if (nextProps.partners !== this.props.partners) return true;
    if (nextProps.allPrices !== this.props.allPrices) return true;
    if (nextState.driverFormShow !== this.state.driverFormShow) return true;

    if (this.props.originalPrice !== nextProps.originalPrice) return true;
    if (this.props.carPrice !== nextProps.carPrice) return true;
    if (this.props.suvPrice !== nextProps.suvPrice) return true;
    if (this.props.vanPrice !== nextProps.vanPrice) return true;
    if (this.props.avgTime !== nextProps.avgTime) return true;
    if (this.state.priceCopy !== nextState.priceCopy) return true;
    if (this.props.sliderValue !== nextProps.sliderValue) return true;
    if (this.props.clientName !== nextProps.clientName) return true;
    if (this.props.telefoneCelular !== nextProps.telefoneCelular) return true;
    if (this.props.companies !== nextProps.companies) return true;
    if (this.props.company !== nextProps.company) return true;
    if (this.props.profiles !== nextProps.profiles) return true;
    if (this.props.loginCompany !== nextProps.loginCompany) return true;
    if (this.props.login !== nextProps.login) return true;
    if (
      nextProps.showMotivoPatio !== this.props.showMotivoPatio ||
      nextState.motivoPatio !== this.state.motivoPatio
    ) {
      return true;
    }
    return false;
  }
  handleMotivoPatioChange = (event) => {
    this.props.updateMotivoPatio(event.target.value);
  };
  componentDidUpdate(prevProps) {
    if (!prevProps.showMotivoPatio && this.props.showMotivoPatio) {
      this.setState({showMotivoPatio: true});
      doOpenModal('CONTEXT_MOTIVO_PATIO');
    }
  }
  pressSolicitation = async (event) => {
    event.preventDefault();
    const {
      veiculoCliente,
      tipoServico,
      enderecoOrigem,
      enderecoDestino,
      cliente,
    } = this.props.serviceInfo;
    const {price} = this.props;

    if (!cliente || !cliente.codigo) {
      this.props.sendServiceError('É necessário informar um cliente');
      return;
    } else if (!veiculoCliente || !veiculoCliente.codigo) {
      this.props.sendServiceError('É necessário informar o veículo');
      return;
    } else if (!enderecoOrigem || !enderecoOrigem.latitude) {
      this.props.sendServiceError('É necessário informar o endereço de origem');
      return;
    } else if (tipoServico == 'REBOQUE' && (!enderecoDestino || !enderecoDestino.latitude)) {
      this.props.sendServiceError('É necessário informar o endereço de destino');
      return;
    } else if (
      this.state.currentSelectedCompany === CODIGO_LOCALIZA &&
      !this.props.serviceInfo.ssPDF
    ) {
      this.props.sendServiceError('É necessário informar o SS PDF');
      return;
    }
    this.setState({
      saveAddress: {...enderecoDestino},
      saveValue: price,
    });

    this.props.onCreateSolicitation(this.props.serviceInfo, {
      username: this.state.company.value,
      password: this.state.company.password,
    });
  };
  handleSubmitMotivoPatio = (event) => {
    const {AddressAfterPatio, saveAddress, saveValue} = this.state;

    event.preventDefault();

    if (!AddressAfterPatio) {
      if (!this.verifyCaptcha()) {
        toastError('Resposta do captcha incorreta!');
        return;
      }
    }

    if (AddressAfterPatio) {
      const posPatio = {
        bairro: this.props.bairro,
        cep: this.props.cep,
        cidade: this.props.cidade,
        estado: this.props.estado,
        latitude: this.props.latDest,
        logradouro: this.props.logradouro,
        longitude: this.props.longDest,
        numero: this.props.numero,
        pais: this.props.pais,
      };

      event.preventDefault();
      this.props.sendSolicitation(
        saveValue,
        {
          username: this.state.company.value,
          password: this.state.company.password,
        },
        posPatio,
        saveAddress,
      );
      this.setState({showMotivoPatio: false});
      doCloseModal('CONTEXT_MOTIVO_PATIO');
    } else {
      event.preventDefault();
      this.props.sendSolicitation(saveValue, {
        username: this.state.company.value,
        password: this.state.company.password,
      });
      this.setState({showMotivoPatio: false});
    }
  };
  clearPrice() {
    // this.setState({ sliderValue: 0 }, () => this.props.clearPrice())
    this.props.clearPrice();
  }

  clearAllPrices() {
    // this.setState({ sliderValue: 0 }, () => this.props.clearAllPrices())
    this.props.clearAllPrices();
  }

  pressSeePrices = async (event) => {
    event.preventDefault();
    const {tipoServico, enderecoOrigem, enderecoDestino} = this.props.serviceInfo;
    if (!enderecoOrigem || !enderecoOrigem.latitude) {
      this.props.sendServiceError('É necessário informar o endereço de origem');
      return;
    } else if (tipoServico == 'REBOQUE' && (!enderecoDestino || !enderecoDestino.latitude)) {
      this.props.sendServiceError('É necessário informar o endereço de destino');
      return;
    }

    this.props.onSeePrices(this.props.serviceInfo, {
      username: this.state.company.value,
      password: this.state.company.password,
    });
  };

  handleSubmit = async () => {
    if (this.props.cliente && this.props.cliente.codigo) {
      this.props.onSendSMS(this.props.cliente.codigo);
    } else {
      this.props.onErrorSMS('Por favor preencha o campo Cliente!');
    }
  };

  toggleStartAddres(value) {
    this.setState({
      AddressAfterPatio: !value,
    });
    this.props.onCheckChange('AddressAfterPatio', !value);
  }

  toggleStartCallPartner(value) {
    this.setState({
      callPartner: !value,
    });
    this.props.onCheckChange('callPartner', !value);
    //event.preventDefault()
  }

  toggleSendSMSCustomer(value) {
    this.setState({
      sendSMSCustomer: !value,
    });
    this.props.onCheckChange('sendSMSCustomer', !value);
    //event.preventDefault()
  }

  handleTabs(key) {
    this.setState({
      key,
    });
    this.props.onKeyChange(key);
    const auth = {
      username: this.state.company.value,
      password: this.state.company.password,
    };
    if (key > 2) {
      //this.props.loadPrice( this.props.serviceInfo )
      if (
        this.props.tipoServico === `REBOQUE` &&
        _.size(this.props.serviceInfo.enderecoOrigem) > 0 &&
        _.size(this.props.serviceInfo.enderecoDestino) > 0 &&
        _.size(this.props.serviceInfo.veiculoCliente) > 0
      ) {
        this.props.loadPrice(this.props.serviceInfo, auth);
      } else {
        this.clearPrice();
      }
      if (
        this.props.tipoServico !== `REBOQUE` &&
        _.size(this.props.serviceInfo.enderecoOrigem) > 0 &&
        _.size(this.props.serviceInfo.veiculoCliente) > 0
      ) {
        this.props.loadPrice(this.props.serviceInfo, auth);
      }
    }
  }

  handleTabsNav(e) {
    const auth = {
      username: this.state.company.value,
      password: this.state.company.password,
    };
    const {saveTender, serviceInfo} = this.props;
    const {key} = this.state;
    if (serviceInfo.veiculoCliente) {
      this.setState({
        tipoVeiculo: serviceInfo.veiculoCliente.tipoVeiculo,
      });
    }
    if (this.props.scheduleDate && moment(this.props.scheduleDate) <= moment(new Date())) {
      return toastError('A data de agendamento deve ser superior a data atual');
    }
    if (this.props.scheduleDate && !moment(this.props.scheduleDate).isValid()) {
      return toastError('A data de agendamento não é válida');
    }
    if (this.tenderValidation()) {
      e.preventDefault();
      if (key < 3) {
        this.setState({
          key: key + 1,
        });
        this.props.onKeyChange(key + 1);
      }
      if (key + 1 > 2) {
        //this.props.loadPrice( this.props.serviceInfo )
        if (
          this.props.tipoServico === `REBOQUE` &&
          _.size(this.props.serviceInfo.enderecoOrigem) > 0 &&
          _.size(this.props.serviceInfo.enderecoDestino) > 0 &&
          _.size(this.props.serviceInfo.veiculoCliente) > 0
        ) {
          this.props.loadPrice(this.props.serviceInfo, auth);
        } else {
          this.clearPrice();
        }
        if (
          this.props.tipoServico !== `REBOQUE` &&
          _.size(this.props.serviceInfo.enderecoOrigem) > 0 &&
          _.size(this.props.serviceInfo.veiculoCliente) > 0
        ) {
          this.props.loadPrice(this.props.serviceInfo, auth);
        }
      }

      if (!serviceInfo.idOrcamento) saveTender();
    } else {
      toastError('Todos os campos são obrigatórios');
    }
  }

  handleService(service) {
    let serviceTipe = 'Reboque';
    if (service === 'BATERIA') {
      serviceTipe = `Bateria`;
    } else if (service === `PANESECA`) {
      serviceTipe = `Pane Seca`;
    } else if (service === `SUBSOLO`) {
      serviceTipe = `Subsolo`;
    } else if (service === `TROCAPNEU`) {
      serviceTipe = `Troca de Pneu`;
    } else {
      return serviceTipe;
    }
    return serviceTipe;
  }
  handleCompany(company) {
    this.setState({
      currentSelectedCompany: company.value,
    });
    this.props.changeCompany(company);
    this.props.loadFavoriteDestiantionList(company.value);

    if (this.tenderValidation()) this.props.getTimeAndPrice();
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  handleObservationPesado = (text, isAdding) => {
    let {observacao} = this.props;
    if (isAdding) {
      observacao += text;
    } else {
      const removeText = text.replace('Remover ', '');
      observacao = observacao.replace(new RegExp(this.escapeRegExp(removeText), 'g'), '');
    }
    this.props.customOnFieldChange('observacao', observacao.trim());
  };
  handleObservation(text) {
    const {observacao, customOnFieldChange} = this.props;
    text = `${observacao} ${text};`;
    customOnFieldChange('observacao', text);
  }

  handleProtocol(text) {
    if (!this.props.ownId) this.props.customOnFieldChange('ownId', text);
  }

  handleModelSelected(modelSelected) {
    this.setState({modelSelected: !!modelSelected});
    this.props.onModelSelected(modelSelected);
  }

  updatePrices() {
    const {
      carPrice,
      originalPrice,
      originalCarPrice,
      originalSuvPrice,
      originalVanPrice,
      suvPrice,
      vanPrice,
      time,
      avgTime,
      price,
    } = this.props;
    const {sliderValue} = this.props;

    this.props.setPrice(
      this.preparedPrice(originalPrice, sliderValue),
      time,
      this.props.cupomValido,
    );
    // console.log(this.preparedPrice(originalCarPrice, sliderValue));
    this.props.setAllPrices(
      this.preparedPrice(originalCarPrice, sliderValue),
      this.preparedPrice(originalSuvPrice, sliderValue),
      this.preparedPrice(originalVanPrice, sliderValue),
      avgTime,
    );
  }

  preparedPrice(price, value) {
    return parseFloat(price + price * (value / 100)).toFixed(0);
  }

  vehicleSelected(v) {
    this.setState({modelSelected: true}, () => {
      this.props.onVehicleSelected(v);
    });
  }

  handleCopy(priceCopy) {
    this.setState({priceCopy}, () => {
      this.copyArea.hidden = false;
      this.copyArea.select();
      document.execCommand('copy');
      this.copyArea.hidden = true;
      toastSuccess('Copiado');
    });
  }

  getCopyValues() {
    const {avgTime, time} = this.props;
    const {priceCopy} = this.state;

    return `Tempo: ${
      time > 0
        ? `${time} - ${(time * 1.3).toFixed(0)}`
        : `${avgTime} - ${(avgTime * 1.3).toFixed(0)}`
    } minutos -
        Preço: R$${priceCopy},00`;
  }

  updateService(
    tenderId,
    codigoEmpresa,
    nomeCliente,
    tipoServico,
    enderecoOrigem,
    enderecoDestino,
    marca,
    modelo,
    tipoVeiculo,
    rodasTravadas,
    blindado,
    telefoneCelular,
    quantidadeRodasTravadas,
  ) {
    const {
      onTenderIdReceived,
      companies,
      changeCompany,
      setClientName,
      changeService,
      onAddressChange,
      onModelReceived,
      onCheckboxChange,
      onRollerChange,
    } = this.props;

    onTenderIdReceived(tenderId);
    const selectedCompany = companies
      .filter((c) => c.codigo.toString() === codigoEmpresa)
      .map((c) => ({value: c.codigo, label: c.nomeFantasia}))[0];
    if (codigoEmpresa === '3') {
      changeCompany({value: '3', label: 'ReboqueMe Web App'});
    } else {
      changeCompany(
        selectedCompany || {
          value: this.props.loginCompany.company,
          label: this.props.loginCompany.nomeFantasia,
        },
      );
    }

    setClientName('nome', nomeCliente);
    setClientName('telefoneCelular', telefoneCelular);
    if (tipoServico) {
      changeService(tipoServico);
    }

    if (enderecoOrigem) {
      enderecoOrigem.type = 'favorite';
      enderecoOrigem.location = {
        lat: enderecoOrigem.latitude,
        lng: enderecoOrigem.longitude,
      };
      onAddressChange('enderecoOrigem', enderecoOrigem);
    } else {
    }
    if (enderecoDestino) {
      enderecoDestino.type = 'favorite';
      enderecoDestino.location = {
        lat: enderecoDestino.latitude,
        lng: enderecoDestino.longitude,
      };
      onAddressChange('enderecoDestino', enderecoDestino);
    }
    if (marca && modelo && tipoVeiculo) {
      onModelReceived({
        marca,
        modelo,
        tipoVeiculo,
        value: `${marca} ${modelo}`,
      });
      this.handleModelSelected({marca, modelo, tipoVeiculo});
    }
    onCheckboxChange('rodasTravadas', rodasTravadas, true);
    onCheckboxChange('blindado', blindado, true);
    onRollerChange('quantidadeRodasTravadas', quantidadeRodasTravadas);
  }

  onTenderItemClick(tender) {
    const {
      tipoServico,
      enderecoOrigem,
      enderecoDestino,
      marca,
      modelo,
      tipoVeiculo,
      id,
      nome,
      situacaoVeiculo,
      codigoEmpresa,
      valorServico,
      telefone,
    } = tender || {};
    let {rodasTravadas, blindado, quantidadeRodasTravadas} = situacaoVeiculo || {};
    if (!situacaoVeiculo) {
      rodasTravadas = false;
      blindado = false;
      quantidadeRodasTravadas = 0;
    }

    this.updateService(
      id,
      codigoEmpresa,
      nome,
      tipoServico,
      enderecoOrigem,
      enderecoDestino,
      marca,
      modelo,
      tipoVeiculo,
      rodasTravadas,
      blindado,
      telefone,
      quantidadeRodasTravadas,
    );
    this.setState({tenderPrice: valorServico});
    this.handleTabs(2);
  }
  clearTender() {
    this.handleModelSelected(false);
    this.props.doClearAll();
    this.props.getTenderList();
    this.setState({freeNav: false, tenderPrice: null});
    location.reload();
  }

  onFieldChangeProtocolo(event) {
    if (event.target.value.length > 50) return;
    this.props.onFieldChange(event);
  }

  tenderValidation() {
    const {serviceInfo, clientName, tipoServico, scheduleDate, isScheduled} = this.props;
    const {company, enderecoOrigem, enderecoDestino, veiculoCliente} = serviceInfo;
    if (
      company &&
      clientName &&
      clientName &&
      enderecoOrigem &&
      !isEmpty(enderecoOrigem) &&
      veiculoCliente &&
      !isEmpty(veiculoCliente) &&
      (!isScheduled || moment(scheduleDate).isValid())
    ) {
      if (tipoServico !== 'REBOQUE') {
        this.setState({freeNav: true});
        return true;
      } else {
        if (enderecoDestino && !isEmpty(enderecoDestino)) {
          this.setState({freeNav: true});
          return true;
        }
      }
    }
    return false;
  }

  render() {
    const {
      preco_categoria,
      cupom_desconto,
      enviar_sms,
      acionar_manualmente,
      enviar_sms_localizador,
      key,
      accountList,
      tipoVeiculo,
      num1,
      num2,
    } = this.state;
    const {serviceFetching} = this.props;
    const value = this.props.value;
    const {containerHeight, serviceInfo, companies, company} = this.props;
    let mapHeight = Math.round(this.state.leftPanelHeight - this.state.floatingPanelHeight);

    let companySelected = false;

    if (this.props.company) {
      companySelected = this.props.company.value === PROTOCOL_CHIP_VISIBLE_FOR;
    }

    const showProtocolChips =
      this.props.login.user_info.empresa.codigo === PROTOCOL_CHIP_VISIBLE_FOR ||
      companySelected;

    return (
      <div className="wrapper wrapper-content" style={{paddingTop: '10px'}}>
        <ReactTooltip />
        <div className="row">
          <div className="col-md-6" ref={(r) => (this.leftPanel = r)}>
            <div
              className="ibox float-e-margins ibox-title"
              style={{minHeight: containerHeight}}>
              <Tabs id="service-tabs" activeKey={this.state.key} onSelect={this.handleTabs}>
                <Tab eventKey={1} title="Orçamentos">
                  <Tender onItemClick={this.onTenderItemClick} />
                </Tab>
                <Tab eventKey={2} title="Solicitação">
                  <form
                    style={{paddingTop: '20px'}}
                    className="form-horizontal"
                    onSubmit={(e) => e.preventDefault()}>
                    {this.props.login.user_info.usuario.tipoUsuario === 1 && (
                      <div>
                        <h4>Empresa</h4>
                        <div className="col-md-12">
                          <div className="form-group">
                            <Select
                              className="company-select"
                              value={company}
                              options={companies.map((c) => ({
                                value: c.codigo,
                                label: c.nomeFantasia,
                              }))}
                              onChange={this.handleCompany}
                              styles={selectCustomStyle}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-6">
                        <h4>Nome do Cliente</h4>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              value={this.props.clientName}
                              onChange={(e) =>
                                this.props.setClientName(e.target.id, e.target.value)
                              }
                              id="nome"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h4>Celular do Cliente</h4>
                        <div className="col-md-12">
                          <div className="form-group">
                            <MaskedInput
                              id="telefoneCelular"
                              className="form-control"
                              disabled={this.props.readonlyDetails}
                              onChange={(e) =>
                                this.props.setClientName(e.target.id, e.target.value)
                              }
                              value={this.props.telefoneCelular}
                              mask="(99) 9999tt999?"
                              formatChars={{
                                9: '[0-9]',
                                t: '[0-9-]',
                                '?': '[0-9 ]',
                              }}
                              maskChar={null}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  {/* SERVICES */}
                  <form className="form-horizontal" onSubmit={(e) => e.preventDefault()}>
                    <h4>Agendamento</h4>
                    <div className="col-md-12">
                      <p style={{textAlign: 'left'}}>
                        <button
                          className={
                            !this.props.isScheduled
                              ? 'btn btn-sm btn-success'
                              : 'btn btn-sm btn-default'
                          }
                          style={{marginRight: '6px'}}
                          type="button"
                          onClick={() => {
                            this.props.clearSchedule();
                          }}>
                          Solicitação imediata
                        </button>
                        <button
                          className={
                            this.props.isScheduled
                              ? 'btn btn-sm btn-success'
                              : 'btn btn-sm btn-default'
                          }
                          style={{marginRight: '6px'}}
                          type="button"
                          onClick={() => {
                            this.props.customOnFieldChange('agendado', true);
                            this.props.customOnFieldChange(
                              'dataAgendada',
                              moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                            );
                          }}>
                          Agendar solicitação
                        </button>
                      </p>
                    </div>
                    {this.props.isScheduled && (
                      <span>
                        <h4>Horário que prestador deve chegar ao cliente</h4>
                        <div className="col-md-12">
                          <p style={{textAlign: 'left'}}>
                            <DateTime
                              strictParsing={false}
                              onChange={(event) =>
                                this.props.customOnFieldChange(
                                  'dataAgendada',
                                  moment(event).format('YYYY-MM-DD HH:mm:ss'),
                                )
                              }
                              value={moment(this.props.scheduleDate)}
                            />
                          </p>
                        </div>
                      </span>
                    )}
                    <h4>Serviços</h4>
                    <div className="col-md-12">
                      <p style={{textAlign: 'left'}}>
                        <OptionChooser
                          context="service"
                          keyField="tipoServico"
                          optionKey="REBOQUE"
                          text="Reboque"
                          onOptionChanged={doServiceChange}
                          doAfterChange={this.props.clearRoller}
                          icon="fa fa-truck"
                        />
                        <OptionChooser
                          context="service"
                          keyField="tipoServico"
                          optionKey="BATERIA"
                          text="Bateria"
                          onOptionChanged={doServiceChange}
                          doAfterChange={this.props.clearRoller}
                          icon="fa fa-battery-quarter"
                        />
                        <OptionChooser
                          context="service"
                          keyField="tipoServico"
                          optionKey="PANESECA"
                          text="Pane Seca"
                          onOptionChanged={doServiceChange}
                          doAfterChange={this.props.clearRoller}
                          icon="fa fa-flask"
                        />
                        <OptionChooser
                          context="service"
                          keyField="tipoServico"
                          optionKey="TROCAPNEU"
                          text="Pneu"
                          onOptionChanged={doServiceChange}
                          doAfterChange={this.props.clearRoller}
                          icon="far fa-circle"
                        />
                        <OptionChooser
                          context="service"
                          keyField="tipoServico"
                          optionKey="SUBSOLO"
                          text="Subsolo"
                          onOptionChanged={doServiceChange}
                          doAfterChange={this.props.clearRoller}
                          icon="fa fa-truck"
                        />
                        <OptionChooser
                          context="service"
                          keyField="tipoServico"
                          optionKey="CHAVEIRO"
                          text="Chaveiro"
                          onOptionChanged={doServiceChange}
                          doAfterChange={this.props.clearRoller}
                          icon="fa fa-key"
                        />
                        {this.state.currentSelectedCompany === CODIGO_JUSTOS && (
                          <OptionChooser
                            context="service"
                            keyField="tipoServico"
                            optionKey="TAXI"
                            text="Transporte de Passageiro "
                            onOptionChanged={doServiceChange}
                            doAfterChange={this.props.clearRoller}
                            icon="fa fa-taxi"
                          />
                        )}
                        {this.state.currentSelectedCompany === CODIGO_JUSTOS && (
                          <OptionChooser
                            context="service"
                            keyField="tipoServico"
                            optionKey="CARRORESERVA"
                            text="Carro Reserva "
                            onOptionChanged={doServiceChange}
                            doAfterChange={this.props.clearRoller}
                            icon="fa fa-shuttle-van"
                          />
                        )}
                        {this.state.currentSelectedCompany === CODIGO_JUSTOS && (
                          <OptionChooser
                            context="service"
                            keyField="tipoServico"
                            optionKey="VIDROSAUTO"
                            text="Vidraceiro Automotivo"
                            onOptionChanged={doServiceChange}
                            doAfterChange={this.props.clearRoller}
                            icon="fas fa-car-side"
                          />
                        )}
                        {(this.state.currentSelectedCompany === CODIGO_LOCALIZA ||
                          this.state.currentSelectedCompany === CODIGO_LOCALIZA_PESADOS) && (
                          <OptionChooser
                            context="service"
                            keyField="tipoServico"
                            optionKey="ANJODAGUARDA"
                            text="Anjo"
                            onOptionChanged={doServiceChange}
                            doAfterChange={this.props.clearRoller}
                            icon="fa fa-user-shield"
                          />
                        )}
                        {this.state.currentSelectedCompany === CODIGO_JUSTOS && (
                          <div>
                            <p style={{textAlign: 'left', lineHeight: '35px'}}>
                              <h4>Residenciais</h4>
                              <OptionChooser
                                context="service"
                                keyField="tipoServico"
                                optionKey="CHAVEIROHOME"
                                text="Chaveiro Residencial"
                                onOptionChanged={doServiceChange}
                                doAfterChange={this.props.clearRoller}
                                icon="fa fa-key"
                              />
                              <OptionChooser
                                context="service"
                                keyField="tipoServico"
                                optionKey="HIDRAULICAHOME"
                                text="Hidráulica Residencial"
                                onOptionChanged={doServiceChange}
                                doAfterChange={this.props.clearRoller}
                                icon="fa fa-wrench"
                              />
                              <OptionChooser
                                context="service"
                                keyField="tipoServico"
                                optionKey="VIDROSHOME"
                                text="Vidraceiro Residencial"
                                onOptionChanged={doServiceChange}
                                doAfterChange={this.props.clearRoller}
                                icon="fa fa-window-maximize"
                              />
                              <OptionChooser
                                context="service"
                                keyField="tipoServico"
                                optionKey="ELETRICAHOME"
                                text="Elétrica Residencial "
                                onOptionChanged={doServiceChange}
                                doAfterChange={this.props.clearRoller}
                                icon="fa fa-bolt"
                              />
                            </p>
                          </div>
                        )}
                      </p>
                    </div>
                  </form>

                  {/* ADRESSESS */}
                  <form
                    style={{paddingTop: '10px'}}
                    className="form-horizontal"
                    onSubmit={(e) => e.preventDefault()}>
                    <h4>Endereços</h4>
                    <div className="form-group">
                      <div className="col-md-12">
                        <div className="input-group">
                          <span className="input-group-btn">
                            <button type="button" className="btn btn-default">
                              <i className="fa fa-location-arrow" />
                            </button>
                          </span>
                          <AddressAutoComplete
                            initialValue={this.props.enderecoOrigem}
                            favoriteDestinations
                            placeholder="Endereço de Origem"
                            id="enderecoOrigem"
                          />{' '}
                          {enviar_sms_localizador ? (
                            <span className="input-group-btn">
                              {' '}
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleSubmit();
                                }}>
                                <i className="fas fa-envelope" />
                              </button>
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div
                      className="form-group"
                      style={{
                        display: this.props.tipoServico == 'REBOQUE' ? 'block' : 'none',
                      }}>
                      <div className="col-md-12">
                        <div className="input-group">
                          <span className="input-group-btn">
                            <button type="button" className="btn btn-default">
                              <i className="fa fa-flag-checkered" />
                            </button>
                          </span>
                          <AddressAutoComplete
                            initialValue={this.props.enderecoDestino}
                            favoriteDestinations
                            placeholder="Endereço de Destino"
                            id="enderecoDestino"
                            onOptionSelected={this.updateValues}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-md-12">
                        {onVehicleSelect}
                        <h4>Marca / Modelo</h4>

                        <ModelAutoCompleteStateful
                          id={VEHICLE_FORM_MODEL_INPUT}
                          context={VEHICLE_FORM_MODEL_INPUT}
                          iconName="fa fa-car"
                          placeholder="Ex: Ford Ka"
                          disabled={this.props.readonlyDetails}
                          afterOptionSelected={this.handleModelSelected}
                          afterClear={() => this.handleModelSelected(false)}
                          auth={this.props.auth}
                        />
                      </div>
                    </div>
                    {this.props.tipoServico == 'REBOQUE' && (
                      <VehicleStatusForm
                        onCheckboxChange={(id, checked) => {
                          this.props.onCheckboxChange(id, checked, this.state.modelSelected);
                        }}
                        onRollerChange={(id, value) => {
                          this.props.onRollerChange(id, value);
                        }}
                        context={MODAL_VEHICLE_STATUS}
                        rodasTravadas={this.props.serviceInfo.rodasTravadas || false}
                        blindado={this.props.serviceInfo.blindado || false}
                        semChaves={this.props.serviceInfo.semChaves || false}
                        quantidadeRodasTravadas={
                          this.props.serviceInfo.quantidadeRodasTravadas || 0
                        }
                      />
                    )}
                  </form>
                  {/* SEND */}
                  <form
                    className="form-horizontal"
                    onSubmit={(e) => e.preventDefault()}
                    style={{paddingTop: `20px`}}>
                    <div className="form-group">
                      <div className="col-md-4">
                        <button
                          className="btn btn-block btn-warning"
                          type="submit"
                          onClick={() => this.clearTender()}>
                          Limpar
                        </button>
                      </div>
                      <div className="col-md-8">
                        <button
                          className="btn btn-block btn-primary"
                          type="submit"
                          onClick={this.handleTabsNav}>
                          {serviceInfo.idOrcamento
                            ? 'Avançar'
                            : 'Avançar para calcular o preço'}
                        </button>
                      </div>
                    </div>
                  </form>

                  <CustomModal title="Enviar SMS de Localização" context={MODAL_FIND}>
                    <FindForm onChange={this.props.onFieldChange} context={MODAL_FIND} />
                  </CustomModal>
                </Tab>
                <Tab eventKey={3} title="Veículo" disabled={!this.state.freeNav}>
                  {/* CLIENT */}
                  <form
                    style={{paddingTop: '20px'}}
                    className="form-horizontal"
                    onSubmit={(e) => e.preventDefault()}>
                    <label>Cliente</label>

                    <div className="form-group">
                      <div className="col-md-12">
                        <CustomerAutoComplete
                          id={CUSTOMER_AUTOCOMPLETE}
                          context={CUSTOMER_AUTOCOMPLETE}
                          focus={false}
                          isRequired={true}
                          onFocus={this.props.onFocusChange}
                          iconName="fa fa-user"
                          placeholder="Nome do Cliente"
                          afterOptionSelected={(selected) => {
                            this.props.onCustomerSelected(selected, {
                              username: this.state.company.value,
                              password: this.state.company.password,
                            });
                            this.focusVehicleInput();
                          }}
                          afterClear={() => {
                            this.props.onCustomerClear();
                            this.focusCustomerInput();
                          }}
                          auth={{
                            username: this.state.company.value,
                            password: this.state.company.password,
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-md-12">
                        <VehicleAutoComplete
                          id={VEHICLE_AUTOCOMPLETE}
                          currentCustomer={this.props.cliente}
                          context={VEHICLE_AUTOCOMPLETE}
                          onFocus={this.props.onFocusChange}
                          iconName="fa fa-car"
                          placeholder="Placa do Carro"
                          afterOptionSelected={(v) => {
                            this.vehicleSelected(v);
                          }}
                          afterClear={() => {
                            this.handleModelSelected(false);
                            this.props.onVehicleClear();
                            this.focusVehicleInput();
                          }}
                          auth={{
                            username: this.state.company.value,
                            password: this.state.company.password,
                          }}
                        />
                      </div>
                    </div>
                  </form>
                  <div className="col-xs-12" style={{margin: '0px 0px 20px -10px'}}>
                    <a
                      href="#"
                      style={{
                        color: '#5c40ff',
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          driverFormShow: !this.state.driverFormShow,
                        });
                      }}>
                      <i
                        className={`${
                          this.state.driverFormShow ? 'fas fa-minus' : 'fa fa-plus'
                        }`}
                      />
                      Adicionar Motorista Diferente
                    </a>
                  </div>
                  <div
                    className="col-xs-12"
                    style={{
                      display: this.state.driverFormShow ? 'block' : 'none',
                      marginLeft: ' -10px',
                    }}>
                    <DriverForm
                      nomeMotorista={this.props.nomeMotorista}
                      onChange={this.props.onFieldChange.bind(this)}
                      context={MODAL_ANOTHER_DRIVER}
                    />
                  </div>

                  {/* <div className="col-md-6">
                          <label>Cupom de Desconto</label>
                          <input className="form-control"
                            onChange={this.props.onFieldChange}
                            onFocus={this.props.onFocusChange}
                            id="cupom"
                            placeholder="Ex: Cupom" />
                          {
                            this.props.cupomValido === true
                              ? <span className="form-control-feedback glyphicon glyphicon-ok" style={{ color: `#13154e` }}></span>
                              : null
                          }
                          {
                            this.props.cupomValido === false
                              ? <span className="form-control-feedback glyphicon glyphicon-remove text-danger" ></span>
                              : null
                          }
                        </div> */}
                  {this.state.currentSelectedCompany &&
                    isSoonOrLocalizaProfile(this.state.currentSelectedCompany) && (
                      <form
                        style={{paddingTop: '20px'}}
                        className="form-horizontal"
                        onSubmit={(e) => e.preventDefault()}>
                        <label>Upload de SS Localiza</label>
                        <div className="form-group">
                          <div className="col-md-12">
                            <input
                              accept=".pdf"
                              type="file"
                              name="ssPDF"
                              id="ssPDF"
                              style={{
                                backgroundColor: '#40baff',
                                borderRadius: 3,
                                color: '#fff',
                                cursor: 'pointer',
                                margin: '10px 0',
                                padding: '6px 12px',
                                fontWeight: 'normal',
                                fontSize: 14,
                              }}
                              onChange={this.props.onFieldChangeFile}
                            />
                          </div>
                        </div>
                      </form>
                    )}

                  <form
                    style={{paddingTop: '10px'}}
                    className="form-horizontal"
                    onSubmit={(e) => e.preventDefault()}>
                    <label>Protocolo</label>
                    <div className="form-group">
                      <div className="col-md-12">
                        <input
                          className="form-control"
                          onChange={this.onFieldChangeProtocolo}
                          onFocus={this.props.onFocusChange}
                          value={this.props.ownId}
                          onBlur={(event) => this.props.customOnFieldChange('ownId', event.target.value.trim())}
                          id="ownId"
                          placeholder="Protocolo"
                        />
                      </div>
                    </div>
                  </form>
                  {showProtocolChips && (
                    <FastButton labels={protocolLabels} btnClick={this.handleProtocol} />
                  )}
                  {/* COMMENTS */}
                  <form
                    style={{paddingTop: '10px'}}
                    className="form-horizontal"
                    onSubmit={(e) => e.preventDefault()}>
                    <label>Observação</label>
                    <div className="form-group">
                      <div className="col-md-12">
                        <textarea
                          rows="3"
                          id="observacao"
                          onChange={this.props.onFieldChange}
                          value={this.props.observacao}
                          placeholder="Informe a situação do veículo"
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </form>
                  <FastButton labels={labels} btnClick={this.handleObservation} />
                  {(tipoVeiculo === 'PESADO' || tipoVeiculo === 'EXTRAPESADO') && (
                    <ObservationForm handleObservation={this.handleObservationPesado} />
                  )}
                  {/* <button
                    onClick={() => this.props.customOnFieldChange("observacao", ``)}
                    >
                    Levar máquina de cartão
                  </button>
                   */}
                  {/*CHECK SOLICITATION*/}
                  <div className="row" />
                  <form
                    style={{paddingTop: '20px'}}
                    className="form-horizontal"
                    onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">
                      {acionar_manualmente ? (
                        <div className="col-md-6">
                          <div className="col-md-6">
                            <label>Acionamento Automático</label>
                          </div>

                          <div className="col-md-6 pull-left">
                            <Toggle
                              inactiveLabel={'Não'}
                              activeLabel={'Sim'}
                              colors={
                                {
                                  activeThumb: {base: `#FFF`},
                                  active: {base: `#5c40ff`},
                                  inactiveThumb: {base: `#FFF`},
                                  inactive: {base: `#FFF`},
                                } //activeLabelStyle={{backgroundColor: `#25f4af`}}
                              }
                              inactiveLabelStyle={{color: `#2f4050`}}
                              value={this.state.callPartner}
                              thumbStyle={{
                                borderRadius: 2,
                                borderColor: `#5c40ff`,
                              }}
                              trackStyle={{borderRadius: 2}}
                              onToggle={this.toggleStartCallPartner}
                            />
                          </div>
                        </div>
                      ) : null}
                      {enviar_sms ? (
                        <div className="col-md-6">
                          <div className="col-md-6">
                            <label>Enviar SMS?</label>
                          </div>

                          <div className="col-md-6 pull-left">
                            <Toggle
                              inactiveLabel={'Não'}
                              activeLabel={'Sim'}
                              value={this.state.sendSMSCustomer}
                              colors={
                                {
                                  activeThumb: {base: `#FFF`},
                                  active: {base: `#5c40ff`},
                                  inactiveThumb: {base: `#FFF`},
                                  inactive: {base: `#FFF`},
                                } //checked={this.state.callPartner}
                              }
                              inactiveLabelStyle={{color: `#2f4050`}}
                              thumbStyle={{
                                borderRadius: 2,
                                borderColor: `#5c40ff`,
                              }}
                              trackStyle={{borderRadius: 2}}
                              onToggle={this.toggleSendSMSCustomer}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </form>

                  {/* SEND */}
                  <form
                    style={{paddingTop: '20px'}}
                    className="form-horizontal"
                    onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">
                      <div>
                        <div className="col-md-12">
                          <button
                            disabled={serviceFetching}
                            className="btn btn-block btn-primary"
                            onClick={(event) => {
                              this.pressSolicitation(event);
                            }}>
                            {this.props.isScheduled ? 'Agendar' : 'Solicitar Atendimento'}
                          </button>
                        </div>
                        {/*<div className="col-md-6">
                          <button className="btn btn-block btn-success"
                            onClick={this.pressSeePrices}>
                           Ver Preços
                          </button>
                        </div>*/}
                      </div>
                    </div>
                    {/* MODAL MotivoPatio */}
                    <CustomModal title="Motivo Patio" context={'CONTEXT_MOTIVO_PATIO'}>
                      <div className="col-md-12" style={{textAlign: 'center'}}>
                        <h4>Informe o motivo de enviar para o pátio:</h4>
                        <textarea
                          className="form-control"
                          id="motivoPatio"
                          rows="3"
                          value={this.props.serviceInfo.motivoPatio}
                          onChange={this.handleMotivoPatioChange}></textarea>

                        {this.props.login.user_info.usuario.tipoUsuario === 1 && (
                          <div>
                            <div style={{marginBottom: '2px', marginTop: '10px'}}>
                              <label>Destino Solicitado?</label>
                              <div className="form-group toggle-group">
                                <Toggle
                                  inactiveLabel={'Não'}
                                  activeLabel={'Sim'}
                                  colors={{
                                    activeThumb: {base: `#FFF`},
                                    active: {base: `#5c40ff`},
                                    inactiveThumb: {base: `#FFF`},
                                    inactive: {base: `#FFF`},
                                  }}
                                  inactiveLabelStyle={{color: `#2f4050`}}
                                  value={this.state.AddressAfterPatio}
                                  thumbStyle={{
                                    borderRadius: 2,
                                    borderColor: `#5c40ff`,
                                  }}
                                  trackStyle={{borderRadius: 2}}
                                  onToggle={this.toggleStartAddres}
                                />
                              </div>
                            </div>
                            {!this.state.AddressAfterPatio && (
                              <div
                                className="mb-3"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  gap: '8px',
                                }}>
                                <label>
                                  Resolva {num1} x {num2} =
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  style={{maxWidth: '150px'}}
                                  onChange={this.handleCaptchaInput}
                                  placeholder="Digite sua resposta"
                                />
                                <small className="text-muted mb-3">
                                  * Resposta correta é necessária para criar a solicitação.
                                </small>
                              </div>
                            )}
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '3px',
                              }}>
                              {this.state.AddressAfterPatio && (
                                <div style={{flexGrow: 1}}>
                                  <AddressAutoComplete
                                    initialValue={this.state.enderecoDestino}
                                    placeholder="Endereço de Destino"
                                    id="enderecoDestino"
                                    onOptionSelected={(endereco) =>
                                      this.setState({enderecoDestino: endereco})
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <button
                          className="btn btn-primary"
                          style={{marginTop: '5px'}}
                          onClick={(event) => {
                            this.handleSubmitMotivoPatio(event, value);
                          }}>
                          Solicitar Atendimento
                        </button>
                      </div>
                    </CustomModal>
                  </form>
                </Tab>
              </Tabs>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="float-e-margins animated fadeInRight"
              ref={(r) => (this.floatingInfo = r)}>
              <input
                type="text"
                hidden
                ref={(copyArea) => (this.copyArea = copyArea)}
                value={this.getCopyValues()}
              />
              {serviceInfo.idOrcamento ? (
                <ServiceFloatIndividual
                  service={this.handleService(this.props.tipoServico)}
                  time={this.props.time}
                  price={this.props.price}
                  copy={this.handleCopy}
                  tenderPrice={this.state.tenderPrice}
                  isTegmaUser={this.props.loginCompany.codigo === TEGMA_CODE}
                  onChange={this.props.changePrice}
                />
              ) : (
                <ServiceFloatingPanel
                  service={this.handleService(this.props.tipoServico)}
                  carPrice={this.props.carPrice}
                  vanPrice={this.props.vanPrice}
                  suvPrice={this.props.suvPrice}
                  time={this.props.avgTime}
                  copy={this.handleCopy}
                  isTegmaUser={this.props.loginCompany.codigo === TEGMA_CODE}
                  onChange={this.props.changePrice}
                />
              )}
              {/* {this.props.login.user_info.usuario.tipoUsuario === 1 && (
                <PriceSlider
                  value={this.props.sliderValue}
                  onAfterChange={this.props.setSliderValue}
                />
              )} */}

              {this.state.key > 2 && (
                <FloatingInfo
                  enderecoOrigem={this.props.enderecoOrigem || null}
                  enderecoDestino={this.props.enderecoDestino || null}
                  tipoServico={this.props.tipoServico}
                />
              )}
            </div>

            <div
              className="ibox float-e-margins animated fadeInRight"
              style={{
                marginTop: '10px',
                display: this.props.rightPanel != PANEL_CUSTOMER ? 'none' : 'block',
              }}>
              <div className="ibox-title">
                <h5>Informações do Cliente</h5>
                <div className="ibox-tools">
                  {this.props.cliente && this.props.cliente.codigo ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-xs"
                      onClick={this.props.editClient}>
                      <i className="fa fa-plus" />
                      Alterar Cliente
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="ibox-content">
                <CustomerForm
                  onSaveDispatch={createNewCustomer(
                    CUSTOMER_FORM,
                    this.props.doAfterSaveCustomer,
                    {
                      username: this.state.company.value,
                      password: this.state.company.password,
                    },
                  )}
                  onUpdateDispatch={updateExistingCustomer(
                    CUSTOMER_FORM,
                    this.props.doAfterSaveCustomer,
                    {
                      username: this.state.company.value,
                      password: this.state.company.password,
                    },
                  )}
                  context={CUSTOMER_FORM}
                />
              </div>
            </div>

            <div
              className="ibox float-e-margins animated fadeInRight"
              style={{
                marginTop: '10px',
                display: this.props.rightPanel != PANEL_VEHICLE ? 'none' : 'block',
              }}>
              <div className="ibox-title">
                <h5>Informações do Veículo</h5>
                <div className="ibox-tools">
                  {this.props.veiculoCliente && this.props.veiculoCliente.codigo ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-xs"
                      onClick={this.props.editVehicle}>
                      <i className="fa fa-plus" />
                      Alterar Veículo
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="ibox-content">
                <VehicleForm
                  context={VEHICLE_FORM}
                  isCustomerDisabled={true}
                  afterCreate={this.props.doAfterSaveVehicle}
                  afterUpdate={this.props.doAfterSaveVehicle}
                  auth={{
                    username: this.state.company.value,
                    password: this.state.company.password,
                  }}
                  handleModelSelected={this.handleModelSelected}
                />
                {updateExistingVehicle(
                  VEHICLE_FORM,
                  CUSTOMER_AUTOCOMPLETE,
                  this.props.doAfterSaveVehicle,
                )}
              </div>
            </div>
            <div
              className="ibox float-e-margins animated fadeInRight "
              style={{
                display: this.props.rightPanel != PANEL_MAPS ? 'none' : 'block',
              }}>
              <div className="">
                {' '}
                {/*coloque a classe ibox-content para dar bordas*/}
                <SolicitationMap
                  id="serviceMap"
                  style={{height: mapHeight + 'px'}}
                  requestOrigin={this.props.requestOrigin}
                  requestDestination={this.props.requestDestination}
                  panel={this.props.rightPanel}
                  tipoServico={this.props.tipoServico}
                  partners={this.props.partners}
                  isAdmin={this.props.login.user_info.usuario.tipoUsuario === 1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    containerHeight: state.window.innerHeight - 150,
    serviceInfo: state.service,
    errorMessage: state.service.errorMessage,
    rightPanel: state.service.rightPanel,
    requestOrigin: state.service.enderecoOrigem.latitude
      ? {
          lat: state.service.enderecoOrigem.latitude,
          lng: state.service.enderecoOrigem.longitude,
        }
      : undefined,
    requestDestination: state.service.enderecoDestino.latitude
      ? {
          lat: state.service.enderecoDestino.latitude,
          lng: state.service.enderecoDestino.longitude,
        }
      : undefined,
    isAnotherDriver: state.service.isAnotherDriver,
    profiles: state.login.profiles,
    login: state.login,
    showMotivoPatio: state.service.showMotivoPatio,
    motivoPatio: state.service.motivoPatio,
    loginCompany: state.login.user_info.empresa,
    tipoServico: state.service.tipoServico,
    veiculoCliente: state.service.veiculoCliente,
    cliente: state.service.cliente,
    serviceFetching: state.service.serviceFetching,
    nomeMotorista: state.service.nomeMotorista,
    enderecoOrigem: state.service.enderecoOrigem.logradouro,
    enderecoDestino: state.service.enderecoDestino.logradouro,
    price: state.service.price,
    originalPrice: state.service.originalPrice,
    time: state.service.time,
    cupomValido: state.service.cupomValido,
    accountList: state.account.accountList,
    addressFocus: state.service.addressFocus ? state.service.addressFocus : false,
    observacao: state.service.observacao,
    ownId: state.service.ownId,
    partners: state.service.partners,
    carPrice: state.service.carPrice,
    suvPrice: state.service.suvPrice,
    vanPrice: state.service.vanPrice,
    originalCarPrice: state.service.originalCarPrice,
    originalSuvPrice: state.service.originalSuvPrice,
    originalVanPrice: state.service.originalVanPrice,
    avgTime: state.service.avgTime,
    sliderValue: state.service.sliderValue,
    clientName:
      state.forms && state.forms['CUSTOMER_FORM'] && state.forms['CUSTOMER_FORM'].nome
        ? state.forms['CUSTOMER_FORM'].nome
        : '',
    telefoneCelular:
      state.forms &&
      state.forms['CUSTOMER_FORM'] &&
      state.forms['CUSTOMER_FORM'].telefoneCelular
        ? state.forms['CUSTOMER_FORM'].telefoneCelular
        : '',
    companies: state.relatorio.companies.filter(
      (c) => c.statusEmpresa.descricao !== 'Inativo' && c.codigo !== '1' && c.codigo !== '3',
    ),
    company: state.service.company,
    isScheduled: state.service.agendado,
    scheduleDate: state.service.dataAgendada,
    logradouro: state.service.enderecoDestino.logradouro,
    latDest: state.service.enderecoDestino.latitude,
    longDest: state.service.enderecoDestino.longitude,
    bairro: state.service.enderecoDestino.bairro,
    cep: state.service.enderecoDestino.cep,
    cidade: state.service.enderecoDestino.cidade,
    estado: state.service.enderecoDestino.estado,
    numero: state.service.enderecoDestino.numero,
    pais: state.service.enderecoDestino.pais,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveTender: () => dispatch(saveTender()),
    changeService: (service) => dispatch(doServiceChange(service)),
    onAddressCompleted: (serviceInfo = null) => {
      dispatch(getAllPricePredictionExecution(serviceInfo));
    },
    getTimeAndPrice: () => {
      dispatch(getPriceAndTimePredictionFormExecution());
    },
    getAllTimeAndPrice: (serviceInfo = null) => {
      dispatch(getAllPricePredictionExecution(serviceInfo));
    },
    updateMotivoPatio: (motivoPatio) => dispatch(updateMotivoPatio(motivoPatio)),
    onModelSelected: (selectedValue) => {
      if (selectedValue) {
        dispatch(onFormFieldChange('VEHICLE_FORM', 'modelo', selectedValue.modelo));
        dispatch(onFormFieldChange('VEHICLE_FORM', 'marca', selectedValue.marca));
        dispatch(onFormFieldChange('VEHICLE_FORM', 'tipoVeiculo', selectedValue.tipoVeiculo));
      }
      dispatch(setFormValues(MODEL_AUTOCOMPLETE, selectedValue)); // Vehicle Form
      dispatch(setReadOnlyMode(MODEL_AUTOCOMPLETE, true)); // Vehicle Form
      dispatch(onVehicleSelect(selectedValue)); // update this service state

      //dispatch(getPriceAndTimePredictionFormExecution(null))
    },
    changeCompany: (company) => {
      dispatch({type: 'SERVICE_COMPANY_CHANGED', payload: company});
    },
    sendServiceError: (message) => {
      dispatch({type: SERVICE_ERROR, message});
    },
    loadFavoriteDestiantionList: (companyId) => {
      dispatch(loadFavoriteDestiantionList(companyId));
    }, // ---------------------
    // CUSTOMER AUTOCOMPLETE
    onCustomerSelected: (selectedValue, auth) => {
      dispatch(setFormValues(CUSTOMER_FORM, selectedValue)); // Customer Form
      dispatch(setReadOnlyMode(CUSTOMER_FORM, true)); // Customer Form
      dispatch(
        setCustomerInputDetails(VEHICLE_FORM_CUSTOMER_INPUT, selectedValue.codigo, auth),
      ); // Customer input on vehicle form
      dispatch(onCustomerSelect(selectedValue)); // update this service state
    },
    onCustomerClear: () => {
      dispatch(clearFormValues(CUSTOMER_FORM));
      dispatch(clearFormValues(VEHICLE_FORM));
      dispatch(onInputCleared(VEHICLE_FORM_CUSTOMER_INPUT));
      dispatch(onInputCleared(CUSTOMER_AUTOCOMPLETE));

      dispatch(onInputCleared(VEHICLE_AUTOCOMPLETE));
      dispatch(onCustomerSelect(undefined));

      dispatch(clearFormValues(VEHICLE_FORM));
      dispatch(onInputCleared(VEHICLE_FORM_MODEL_INPUT));
      dispatch(onVehicleSelect(undefined)); // update this service state
      dispatch(clearPrice());
    },
    // ---------------------
    // CUSTOMER FORM
    doAfterSaveCustomer: (result, auth) => {
      dispatch(setReadOnlyMode(CUSTOMER_FORM, true)); // Customer Form
      dispatch(
        setCustomerInputDetails(VEHICLE_FORM_CUSTOMER_INPUT, result.cliente.codigo, auth),
      ); // Customer input on vehicle form
      dispatch(setCustomerInputDetails(CUSTOMER_AUTOCOMPLETE, result.cliente.codigo, auth)); // Customer Autocomplete on service
      dispatch(onCustomerSelect(result.cliente)); // update this service state
      $('#VEHICLE_AUTOCOMPLETE').focus();
    },
    // ---------------------
    // VEHICLE AUTOCOMPLETE
    onVehicleSelected: (selectedValue) => {
      dispatch(setFormValues(VEHICLE_FORM, selectedValue)); // Vehicle Form
      dispatch(setMarcaModeloInputDetails(VEHICLE_FORM_MODEL_INPUT, selectedValue)); // Marca/Modelo input on vehicle form
      dispatch(setReadOnlyMode(VEHICLE_FORM, true)); // Vehicle Form
      dispatch(onVehicleSelect(selectedValue)); // update this service state
      dispatch(setAddressFocus()); // focus on OriginAddress
    },
    onVehicleClear: () => {
      dispatch(clearFormValues(VEHICLE_FORM));
      dispatch(onInputCleared(VEHICLE_FORM_MODEL_INPUT));
      dispatch(onVehicleSelect(undefined)); // update this service state
      dispatch(clearPrice());
    },
    // ---------------------
    // VEHICLE FORM
    doAfterSaveVehicle: (customerCode, vehicle, auth) => {
      dispatch(
        setVehicleDetails(
          onOptionSelected,
          VEHICLE_AUTOCOMPLETE,
          customerCode,
          vehicle.codigo,
          auth,
        ),
      ); // Vehicle Autocomplete on service
      dispatch(setReadOnlyMode(VEHICLE_FORM, true)); // Vehicle Form
      dispatch(onVehicleSelect(vehicle)); // update this service state
      dispatch(setAddressFocus()); // focus on OriginAddress
    },
    // --------------------------
    focusOnAddress: () => {
      dispatch(setAddressFocus());
    },
    // -----------------------

    // ---------------------
    // SERVICE VIEW COMPONENT (THIS!)
    onFocusChange: (event) => {
      if (event.target.id == CUSTOMER_AUTOCOMPLETE) dispatch(showPanel(PANEL_CUSTOMER));

      if (event.target.id == VEHICLE_AUTOCOMPLETE) dispatch(showPanel(PANEL_VEHICLE));
    },
    onKeyChange: (key) => {
      if (key === 3) {
        dispatch(showPanel(PANEL_CUSTOMER));
      } else if (key === 2) {
        dispatch(showPanel(PANEL_MAPS));
      } else {
        dispatch(showPanel(PANEL_STEPS));
      }
    },
    customOnFieldChange: (id, value) => {
      dispatch(onFieldChange(id, value));
    },
    onFieldChange: (event) => {
      dispatch(onFieldChange(event.target.id, event.target.value));
    },
    onFieldChangeFile: (event) => {
      dispatch(onFieldChange(event.target.id, event.target.files[0]));
    },
    onCheckChange: (name, value) => {
      dispatch(onFieldChange(name, value));
    },
    onCheckboxChange: (id, checked, modelSelected) => {
      if (id === 'rodasTravadas') {
        dispatch(onFieldChange('quantidadeRodasTravadas', 1));
      }
      dispatch(onFieldChange(id, checked));
      if (modelSelected) dispatch(getPriceAndTimePredictionFormExecution());
      else {
        // dispatch(getAllPricePredictionExecution());
      }
    },
    onRollerChange: (id, value) => {
      dispatch(onFieldChange(id, value));
    },
    clearRoller: () => {
      dispatch(onFieldChange('blindado', false));
      dispatch(onFieldChange('rodasTravadas', false));
      dispatch(onFieldChange('quantidadeRodasTravadas', 0));
    },
    sendSolicitation: (price, auth, posPatio, saveAddress) =>
      dispatch(sendSolicitation(price, auth, posPatio, saveAddress)),
    onSeePrices: (data, auth) => {
      confirmDialog(
        {
          title: '<h2>Atualizando preço e tempo...</h2>',
          text: `<span style="color:#F8BB86">Ao confirmar a solicitação do serviço não será possível mais cancelar.<span style="color:#F8BB86">`,
          type: 'info',
          html: true,
          confirmButtonColor: '#25f4af',
          confirmButtonText: 'Confirmar',
          cancelButtonColor: '#d9534f',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          showCancelButton: true,
        },
        () => null,
        () => close(),
      );

      //disableButtons()
      dispatch(getPriceAndTimePreview(data, null, auth));
    },
    loadPrice: (data, auth) => {
      dispatch(getPriceAndTimePredictionForm(data, auth));
    },
    setPrice: (price, time, cupomValido) => {
      dispatch({type: 'LOAD_PRICES', price, time, cupomValido});
    }, // tempoEspera: r.tempoEspera,
    // tipoVeiculo: r.tipoVeiculo,
    // valor: r.valor
    setAllPrices: (carPrice, suvPrice, vanPrice, avgTime) => {
      dispatch({
        type: 'ALL_PRICES_FETCHED',
        carPrice,
        suvPrice,
        vanPrice,
        avgTime,
      });
    },
    clearSchedule: () => {
      dispatch(clearSchedule());
    },
    clearPrice: () => {
      dispatch(clearPrice());
    },
    clearAllPrices: () => {
      dispatch(clearAllPrices());
    },
    clearError: () => {
      dispatch(clearErrorMessage());
    },
    onCreateSolicitation: (data, auth) => {
      dispatch(getPriceAndTimePrediction(data, auth));
    },
    onSendSMS: (data) => {
      confirmDialogSMS(
        {
          title: '<h2>Confirma o envio do SMS?</h2>',
          text: `<span style="color:#F8BB86">Será enviado um SMS com o link para a localização do cliente.<span style="color:#F8BB86">`,
          type: 'info',
          html: true,
          confirmButtonColor: '#25f4af',
          confirmButtonText: 'Confirmar',
          cancelButtonColor: '#d9534f',
          cancelButtonText: 'Cancelar',
          //showLoaderOnConfirm: true,
          showCancelButton: true,
        },
        () => sendSmsFindMe(data),
      );

      //disableButtons()
    },
    onErrorSMS: (text) => {
      dispatch(toastWarning(text));
    },
    onErrorSolicitation: (message) => {
      customToastError(message);
      //dispatch( clearErrorMessage() )
    },
    doPartialClear: () => {
      dispatch(onInputCleared(CUSTOMER_AUTOCOMPLETE));
      dispatch(onInputCleared(VEHICLE_FORM_MODEL_INPUT));
      dispatch(clearFormValues(CUSTOMER_FORM));

      dispatch(onInputCleared(VEHICLE_AUTOCOMPLETE));
      dispatch(clearFormValues(VEHICLE_FORM));
      dispatch(clearFormValues(MODEL_AUTOCOMPLETE));
      dispatch(onInputCleared(VEHICLE_FORM_CUSTOMER_INPUT));
    },
    doClearAll: () => {
      dispatch(clearServiceState());
      dispatch(onInputCleared(CUSTOMER_AUTOCOMPLETE));
      dispatch(onInputCleared(VEHICLE_FORM_MODEL_INPUT));
      dispatch(clearFormValues(CUSTOMER_FORM));

      dispatch(onInputCleared(VEHICLE_AUTOCOMPLETE));
      dispatch(clearFormValues(VEHICLE_FORM));
      dispatch(clearFormValues(MODEL_AUTOCOMPLETE));
      dispatch(onInputCleared(VEHICLE_FORM_CUSTOMER_INPUT));
    },
    informArrival: (pressSolicitation, onFieldChange, event) => {
      dispatch(onInformArrival(pressSolicitation, onFieldChange, event));
    },
    editClient: (context) => {
      dispatch(setEditingMode(CUSTOMER_FORM, true));
      dispatch(setReadOnlyMode(CUSTOMER_FORM, false));
    },
    editVehicle: (context) => {
      dispatch(setEditingMode(VEHICLE_FORM, true));
      dispatch(setReadOnlyMode(VEHICLE_FORM, false));
    },
    getAccountList: () => dispatch(getAccountList()),
    setSliderValue: (sliderValue) =>
      dispatch([
        {type: 'SLIDER_VALUE_CHANGED', sliderValue},
        getPriceAndTimePredictionFormExecution(),
        // getAllPricePredictionExecution(),
      ]),
    changePrice: (price) => dispatch([{type: 'SET_PRICE', price}]),
    onAddressChange: (id, address) => dispatch([onAddressChange(id, address)]),
    onModelReceived: (model) => dispatch(onOptionSelected(VEHICLE_FORM_MODEL_INPUT, model)),
    onTenderIdReceived: (idOrcamento) =>
      dispatch({type: 'TENDER_ID_FETCHED', payload: idOrcamento}),
    setClientName: (id, value) => {
      dispatch(onFormFieldChange(CUSTOMER_FORM, id, value));
    },
    getCompanies: () => dispatch(getCompanies(CompaniesContracts.DEMANDA)),
    getTenderList: () => dispatch(getTenderList()),
  };
};

const Service = connect(mapStateToProps, mapDispatchToProps)(ServiceView);

export default Service;
